import { FC } from 'react';

import NextNProgress from 'nextjs-progressbar';

import { useTheme } from 'styled-components';

import { Container } from './styles';

export const TopBar: FC = () => {
  const theme = useTheme();

  return (
    <>
      <Container />

      <NextNProgress
        color={theme.colors.primary}
        height={5}
        showOnShallow={false}
        options={{
          showSpinner: false,
          speed: 500,
        }}
      />
    </>
  );
};
