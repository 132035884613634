/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @next/next/no-css-tags */
/* eslint-disable @next/next/no-sync-scripts */
import Head from 'next/head';
import type { AppProps } from 'next/app';

import { routes } from '~/shared/constants/routes';

import { TopBar } from '~/shared/components/TopBar';
import { DefaultLayout } from '~/shared/components/DefaultLayout';
import { AuthLayout } from '~/shared/components/AuthLayout';
import { AppProvider } from '~/shared/components/AppProvider';

import { GlobalStyle } from '~/shared/styles/global';

import '~/shared/utils/yupValidations';

const authRoutes = [...Object.values(routes.AUTH)];

export default function MyApp({
  Component,
  pageProps,
  router,
}: AppProps): JSX.Element {
  const Layout = authRoutes.includes(
    router.pathname === '/' ? '/' : router.pathname
  )
    ? AuthLayout
    : DefaultLayout;

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="Criada pela RS Solutions com o propósito de ser o maior o ecossistema de vendas do Brasil, integrando as vendas do meio físico e online numa única plataforma."
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#30bb88" />

        <title>Portal do Administrador | SERU</title>
      </Head>

      <AppProvider>
        {/* @ts-ignore */}
        <GlobalStyle />

        <TopBar />

        <Layout>
          <Component {...pageProps} />
        </Layout>
      </AppProvider>
    </>
  );
}
