import { ISelectOption } from '~/shared/components/Form/Select/types';

import { IIntegrationApp } from '~/shared/services/api/interfaces/IIntegrationApp';

import { ILoadResalesOptionsFilters } from './interfaces/ILoadResalesOptionsFilters';
import { ILoadResalesFilters } from './interfaces/ILoadResalesFilters';

import { ResaleResponseDTO } from './dtos/ResaleResponseDTO';
import { ResaleRequestDTO } from './dtos/ResaleRequestDTO';
import { PlanResponseDTO } from './dtos/PlanResponseDTO';
import { PaginationResponseDTO } from './dtos/PaginationResponseDTO';
import { CompanySubscriptionDTO } from './dtos/CompanySubscriptionDTO';
import { AddActionRequestDTO } from './dtos/AddActionRequestDTO';

import { api, setupApi } from '.';

export async function loadResales(
  queries = {} as ILoadResalesFilters,
  ctx = undefined
): Promise<PaginationResponseDTO<ResaleResponseDTO[]>> {
  const response = await setupApi(ctx).get<
    PaginationResponseDTO<ResaleResponseDTO[]>
  >('/resales', { params: queries });

  return response.data;
}

export async function loadResaleById(
  id: string,
  ctx = undefined
): Promise<ResaleResponseDTO> {
  const response = await setupApi(ctx).get<ResaleResponseDTO>(`/resales/${id}`);

  return response.data;
}

export async function loadResaleMasterByUserId(
  userId: string,
  ctx = undefined
): Promise<ResaleResponseDTO> {
  const response = await setupApi(ctx).get<ResaleResponseDTO>(
    `/users/${userId}/resaleMaster`
  );

  return response.data;
}

export async function loadResalePlanById(
  id: string,
  ctx = undefined
): Promise<PlanResponseDTO> {
  const response = await setupApi(ctx).get<PlanResponseDTO>(
    `/resales/${id}/plan`
  );

  return response.data;
}

export async function loadResaleSubscriptionById(
  id: string,
  ctx = undefined
): Promise<CompanySubscriptionDTO> {
  const response = await setupApi(ctx).get<CompanySubscriptionDTO>(
    `/resales/${id}/subscription`
  );

  return response.data;
}

export async function loadResalesOptions(
  filters = {} as ILoadResalesOptionsFilters,
  ctx = undefined
): Promise<ISelectOption[]> {
  const response = await setupApi(ctx).get<ISelectOption[]>(
    '/resales/options',
    { params: filters }
  );

  return response.data || [];
}

export async function addActionToResales(
  ids: string[],
  data: AddActionRequestDTO,
  ctx = undefined
): Promise<any> {
  await Promise.all(
    ids.map(async (id) => {
      await setupApi(ctx).post(`/resales/${id}/actions`, {
        feedback: data.feedback,
        action: data.action._id,
      });
    })
  );
}

export async function updateResale(
  id: string,
  data: ResaleRequestDTO,
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).put(`/resales/${id}`, data);
}

export async function createResale(
  data: ResaleRequestDTO,
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).post('/resales', data);
}

export async function loadResaleIntegrationApp(
  id: string
): Promise<IIntegrationApp> {
  const response = await api.get<IIntegrationApp>(
    `/resales/${id}/integration-app`
  );

  return response.data;
}

export async function saveResaleIntgrationApp(
  resaleId: string,
  permissionsIds: string[]
): Promise<IIntegrationApp> {
  const response = await api.post<IIntegrationApp>(
    `/resales/${resaleId}/integration-app`,
    { permissions: permissionsIds }
  );
  return response.data;
}
