/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import qs from 'qs';

export function formatObjectToQueries(object: Record<string, any>): string {
  if (Object.values(object || {}).length === 0) return '';

  const formattedObject = Object.entries(object).reduce((acc, [key, value]) => {
    const newAcc = { ...acc };

    if (!!key && !!value) {
      newAcc[key] = value;
    }

    return newAcc;
  }, {} as Record<string, any>);

  if (Object.values(formattedObject || {}).length === 0) return '';

  return `?${qs.stringify(formattedObject)}`;
}
