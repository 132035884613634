/* eslint-disable import/no-cycle */
import Router from 'next/router';

import { destroyCookie, parseCookies, setCookie } from 'nookies';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { AuthTokenError } from '~/shared/errors/AuthTokenError';

import { routes } from '~/shared/constants/routes';
import { envs } from '~/shared/constants/envs';
import { cookies } from '~/shared/constants/cookies';

export function setupApi(ctx = undefined): AxiosInstance {
  const parsedCookies = parseCookies(ctx);

  const api = axios.create({
    baseURL: envs.API_URL,
    headers: {
      ...(parsedCookies &&
        parsedCookies[cookies.AUTH_TOKEN] && {
          Authorization: `Bearer ${parsedCookies[cookies.AUTH_TOKEN]}`,
        }),
      ...(!!ctx && {
        'x-integration-key': envs.API_INTEGRATION_KEY,
      }),
    },
  });

  api.interceptors.response.use(
    (success) => success,
    (error: AxiosError<any>) => {
      if (error.response?.status === 401) {
        if (error.response?.data?.code === 'token_expired') {
          if (typeof window !== 'undefined') {
            destroyCookie(null, cookies.AUTH_TOKEN, { path: '/' });
            api.defaults.headers['Authorization'] = '';
            Router.push(routes.AUTH.SIGN_IN);
          } else {
            return Promise.reject(new AuthTokenError());
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
}

export const api = setupApi();

export const setApiDefaults = (token: string | null): void => {
  if (!api) return;

  if (!token) {
    destroyCookie(null, cookies.AUTH_TOKEN, { path: '/' });
    api.defaults.headers['Authorization'] = '';
    return;
  }

  api.defaults.headers['Authorization'] = `Bearer ${token}`;
  setCookie(null, cookies.AUTH_TOKEN, token, { path: '/' });
};

export * from './auth';
export * from './users';
export * from './customers';
export * from './resales';
export * from './actions';
export * from './companies';
export * from './permissions';
export * from './permissionsGroups';
export * from './plans';
export * from './offers';
export * from './homeSteps';
export * from './sidebarItems';
export * from './files';
export * from './subscriptions';
