import { setupApi } from '.';

export async function uploadFile(file: File, ctx = undefined): Promise<string> {
  const fileData = new FormData();
  fileData.append('file', file);

  const response = await setupApi(ctx).post(`/files/img`, fileData);

  return response.data.result;
}
