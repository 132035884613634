import { ISelectOption } from '~/shared/components/Form/Select/types';

import { formatOffersToOptions } from '~/shared/services/api/utils/formatOffersToOptions';

import { ILoadOffersFilters } from './interfaces/ILoadOffersFilters';

import { OfferResponseDTO, OfferStatus } from './dtos/OfferResponseDTO';
import { OfferRequestDTO } from './dtos/OfferRequestDTO';

import { setupApi } from '.';

export async function loadOfferById(
  id: string,
  ctx = undefined
): Promise<OfferResponseDTO> {
  const response = await setupApi(ctx).get<OfferResponseDTO>(`/offers/${id}`);
  return response.data;
}

export async function loadOffers(
  filters = {} as ILoadOffersFilters,
  ctx = undefined
): Promise<OfferResponseDTO[]> {
  const params: Record<string, any> = {};

  if (filters.status) {
    params.status = filters.status;
  }

  const response = await setupApi(ctx).get<OfferResponseDTO[]>('/offers', {
    params,
  });

  return response.data;
}

export async function loadOffersOptions(
  ctx = undefined
): Promise<ISelectOption[]> {
  const response = await setupApi(ctx).get<OfferResponseDTO[]>('/offers', {
    params: { status: 'active' },
  });

  const formattedOffers = formatOffersToOptions(response.data);

  return formattedOffers;
}

export async function updateOfferStatus(
  id: string,
  status: OfferStatus,
  ctx = undefined
): Promise<OfferResponseDTO> {
  const response = await setupApi(ctx).patch(
    `/offers/${id}/status`,
    {},
    { params: { status } }
  );

  return response.data;
}

export async function updateOffer(
  id: string,
  data: Omit<OfferRequestDTO, '_id'>,
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).put(`/offers/${id}`, data);
}

export async function createOffer(
  data: Omit<OfferRequestDTO, '_id'>,
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).post('/offers', data);
}
