import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 99;
`;

export const SideBarBackdrop = styled.div`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.backdrop};
  z-index: 15;
  opacity: 0;
  transition: opacity ease-in 0.2s;

  cursor: pointer;
`;
