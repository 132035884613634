import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.backdrop};

  z-index: 999;

  transition: opacity 0.25s;
  opacity: 0;

  > div {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 400px;
    width: 100%;
    height: 100%;

    background-color: ${({ theme }) => theme.colors.surface};
    box-shadow: ${({ theme }) => theme.boxShadow.sm};

    transform: translateX(400px);
    transition: all ease-out 0.3s;
    opacity: 0;
  }
`;

export const Content = styled.div`
  max-height: 100%;

  display: flex;
  flex-direction: column;
`;
