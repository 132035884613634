import { permissionsRoutes } from '~/shared/constants/permissionsRoutes';

import { IUser } from '~/modules/auth/interfaces/IUser';

export function getAnyValidRouteWithPermission(
  user: Pick<IUser, 'permissionsGroup'> | null
): string {
  if ((user.permissionsGroup?.permissionIds || []).length === 0) {
    return undefined;
  }

  const findPermission = Object.keys(permissionsRoutes).find((permission) => {
    return user.permissionsGroup.permissionIds.find(
      (permisisonId) => permisisonId === permission
    );
  });

  return findPermission && permissionsRoutes[findPermission];
}
