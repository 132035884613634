/* eslint-disable react/no-array-index-key */
import { FC, useState } from 'react';

import { CollapseArrow } from '~/shared/components/Collapse/CollapseArrow';
import { Collapse } from '~/shared/components/Collapse';

import { Container } from './styles';

import { NavItem, INavItemProps } from '../NavItem';

export const NavMenu: FC<INavItemProps> = ({
  icon: Icon,
  label,
  comingSoon,
  menu,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggle = (): void => {
    if (!comingSoon) {
      setOpen((prevState) => !prevState);
    }
  };

  return (
    <Container isActive={false} isDisabled={comingSoon}>
      <NavItem
        icon={Icon}
        comingSoon={comingSoon}
        label={label}
        onClick={toggle}
        endContent={<CollapseArrow open={open} />}
      />

      <Collapse isOpen={open}>
        {menu.map((item) => (
          <>
            {!item.permissions ||
              (item.permissions.some((p) => !!p) && (
                <NavItem
                  isSubMenu
                  key={`${String(item.href)}-${item.label}`}
                  {...item}
                />
              ))}
          </>
        ))}
      </Collapse>
    </Container>
  );
};
