import { ISelectOption } from '~/shared/components/Form/Select/types';

import { PermissionsGroupResponseDTO } from '../dtos/PermissionsGroupResponseDTO';

export function formatPermissionsGroupsToOptions(
  permissionsGroups: PermissionsGroupResponseDTO[] = []
): ISelectOption[] {
  return permissionsGroups.map((permissionsGroup) => ({
    value: permissionsGroup._id,
    label: permissionsGroup.name,
  }));
}
