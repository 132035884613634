import { forwardRef, ForwardRefRenderFunction } from 'react';

import { FiX } from 'react-icons/fi';

import Image from 'next/image';

import packageInfo from '~/../package.json';
import { Tooltip } from '@chakra-ui/react';

import seruLogoImg from '~/shared/assets/images/seru-logo.png';

import { SideBarContainer, LogoContainer } from './styles';

import { SideBarNav } from './SideBarNav';
import { ToolbarIconButton } from '../Toolbar/ToolbarIconButton';

interface ISideBarProps {
  onClose?(): void;
}

const BaseSideBar: ForwardRefRenderFunction<HTMLElement, ISideBarProps> = (
  { onClose = () => null },
  ref
) => {
  return (
    <SideBarContainer ref={ref}>
      <hr />

      <header>
        <LogoContainer>
          <Image
            height={28}
            width={116}
            alt="SERU"
            src={seruLogoImg.src}
            objectFit="contain"
            objectPosition="left"
          />
        </LogoContainer>

        <ToolbarIconButton icon={FiX} tooltip="Fechar" onClick={onClose} />
      </header>

      <SideBarNav />

      <footer>
        <Tooltip hasArrow label="Versão do sistema" closeOnClick={false}>
          <span>{`v${packageInfo.version}`}</span>
        </Tooltip>
      </footer>
    </SideBarContainer>
  );
};

export const SideBar = forwardRef(BaseSideBar);
