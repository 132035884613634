import { ILoadCompaniesFilters } from '../interfaces/ILoadCompaniesFilters';

export function formatCompaniesFiltersToParams(
  filters: ILoadCompaniesFilters
): Record<string, string> {
  const params: Record<string, string> = {};

  if (filters.limit) {
    params.limit = String(filters.limit);
  }

  if (filters.page) {
    params.page = String(filters.page);
  }

  if (filters.createdAt) {
    params.createdAt = filters.createdAt;
  }

  if (filters.resale) {
    params.resale = filters.resale;
  }

  if (filters.search) {
    params.filter = filters.search;
  }

  if (filters.plan) {
    params.plans = filters.plan;
  }

  if (filters.status) {
    params.status = filters.status;
  }

  if (filters.state) {
    params.state = filters.state;
  }

  if (filters.city) {
    params.city = filters.city;
  }

  if (filters.personType) {
    params.personType = filters.personType;
  }

  return params;
}
