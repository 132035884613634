import { formatObjectToQueries } from '~/shared/utils/formatObjectToQueries';

import { ISubscribersFilters } from '~/modules/subscribers/interfaces/ISubscribersFilters';
import { IResalesFilters } from '~/modules/resales/interfaces/IResalesFilters';
import { ILeadsFilters } from '~/modules/leads/interfaces/ILeadsFilters';

export const routes = {
  AUTH: {
    SIGN_IN: '/',
    FORGOT_PASSWORD: '/forgot-password',
    SETUP: '/setup',
    PASSWORD_RECOVERY: '/password-recovery',
  },
  DASHBOARD: '/dashboard',
  LEADS: {
    LIST: (
      filters = {} as Partial<Pick<ILeadsFilters, 'pendings' | 'archived'>>
    ): string => `/leads${formatObjectToQueries(filters)}`,
    CREATE: '/leads/create',
    UPDATE: (leadId: string): string => `/leads/${leadId}`,
  },
  SUBSCRIBERS: {
    LIST: (
      filters = {} as Partial<Pick<ISubscribersFilters, 'status'>>
    ): string => `/subscribers${formatObjectToQueries(filters)}`,
    SHOW_DETAILS: (subscriberId: string): string =>
      `/subscribers/${subscriberId}`,
    SHOW_SUBSCRIPTION_DETAILS: (subscriberId: string): string =>
      `/subscribers/${subscriberId}/subscription`,
    SHOW_CONSUMPTION_DETAILS: (subscriberId: string): string =>
      `/subscribers/${subscriberId}/consumption`,
  },
  ACTIONS: '/actions',
  OFFER_PURCHASES: {
    LIST: '/offer-purchases',
    UPDATE: (id: string): string => `/offer-purchases/${id}`,
  },
  OFFERS: {
    LIST: '/offers',
    CREATE: '/offers/create',
    UPDATE: (id: string): string => `/offers/${id}`,
  },
  COURSES: '/courses',
  RESALES: {
    LIST: (filters = {} as Partial<Pick<IResalesFilters, 'status'>>): string =>
      `/resales${formatObjectToQueries(filters)}`,
    CREATE: '/resales/create',
    UPDATE: (resaleId: string): string => `/resales/${resaleId}`,
    SHOW_SUBSCRIPTION_DETAILS: (resaleId: string): string =>
      `/resales/${resaleId}/subscription`,
    CREATE_SUBSCRIPTION: (resaleId: string): string =>
      `/resales/${resaleId}/subscription/checkout`,
  },
  USERS: '/users',
  PERMISSIONS_GROUPS: '/permissions-groups',
  HELP: {
    LIST: '/help',
    DETAILS: '/help/details',
  },
  PLANS: {
    LIST: '/plans',
    CREATE: '/plans/create',
    UPDATE: (planId: string): string => `/plans/${planId}`,
  },
  RESOURCES_GROUPS: {
    LIST: '/resources-groups',
    CREATE: '/resources-groups/create',
    UPDATE: (resourcesGroupId: string): string =>
      `/resources-groups/${resourcesGroupId}`,
  },
  ADDITIONAL_SERVICES: {
    LIST: '/additional-services',
  },
};
