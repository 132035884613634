import { CSSProperties } from 'styled-components';
import { Collapse as ChakraCollapse } from '@chakra-ui/react';

import { withIsMounted } from '~/shared/utils/withIsMounted';

import { WithChildren } from '~/shared/types/WithChildren';

interface ICollapseProps {
  isOpen?: boolean;
  contentStyle?: CSSProperties;
  startingHeight?: number;
}

export const Collapse: WithChildren<ICollapseProps> = withIsMounted(
  ({ isOpen = false, children, contentStyle, startingHeight }) => {
    return (
      <ChakraCollapse
        in={isOpen}
        animateOpacity
        style={contentStyle}
        startingHeight={startingHeight}
      >
        {children}
      </ChakraCollapse>
    );
  }
);
