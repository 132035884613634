import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => theme.spacing.md};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};

  > h2 {
    font-size: 20px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.onSurface};
    line-height: 22px;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    transition: opacity ease-in 0.3s;

    > svg {
      width: 16px;
      height: 16px;
      stroke-width: 3px;
      color: ${({ theme }) => theme.colors.onSurface};
    }

    &:hover {
      opacity: 0.5;
    }
  }
`;
