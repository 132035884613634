import { TSubscriberSubscriptionStatus } from '~/modules/subscribers/interfaces/ISubscriberSubscription';

import { SubscriptionRequestDTO } from './dtos/SubscriptionRequestDTO';
import { CompanySubscriptionDTO } from './dtos/CompanySubscriptionDTO';
import { CompanySubscriptionCardDTO } from './dtos/CompanySubscriptionCardDTO';

import { setupApi } from '.';

export async function createSubscription(
  data: SubscriptionRequestDTO,
  ctx = undefined
): Promise<CompanySubscriptionDTO> {
  const response = await setupApi(ctx).post<CompanySubscriptionDTO>(
    '/subscriptions',
    {
      company: data.company,
      plan: data.plan,
      paymentType: data.paymentType,
      resale: data.resale,
      recurrencyType: data.recurrencyType,
      billingInfo: data.billingInfo,
      ...(data.paymentType === 'credit_card' &&
        Object.values(data.card || {}).length > 0 && {
          card: {
            holderName: data.card.holderName,
            cvv: data.card.securityCode,
            expirationDate: data.card.expirationDate,
            number: data.card.number,
            flag: data.card.flag,
          },
        }),
    }
  );

  return response.data;
}

export async function updateSubscriptionPlan(
  subscriptionId: string,
  planId: string,
  ctx = undefined
): Promise<CompanySubscriptionDTO> {
  const response = await setupApi(ctx).patch(
    `/subscriptions/${subscriptionId}/plan`,
    { plan: planId }
  );

  return response.data;
}

export async function updateSubscriptionPaymentType(
  subscriptionId: string,
  paymentType: CompanySubscriptionDTO['paymentType'],
  card?: CompanySubscriptionCardDTO,
  ctx = undefined
): Promise<CompanySubscriptionDTO> {
  const response = await setupApi(ctx).patch(
    `/subscriptions/${subscriptionId}/payment-type`,
    {
      paymentType,
      ...(paymentType === 'credit_card' &&
        Object.values(card || {}).length > 0 && {
          card: {
            holderName: card.holderName,
            cvv: card.securityCode,
            expirationDate: card.expirationDate,
            number: card.number,
            flag: card.flag,
          },
        }),
    }
  );

  return response.data;
}

export async function changeSubscriptionStatus(
  subscriptionId: string,
  status: TSubscriberSubscriptionStatus,
  reason: string,
  hasPaidLastDebit = false,
  voucher = null,
  ctx = undefined
): Promise<CompanySubscriptionDTO> {
  const response = await setupApi(ctx).patch(
    `/subscriptions/${subscriptionId}/status`,
    { reason, hasPaidLastDebit, status, voucher }
  );

  return response.data;
}
