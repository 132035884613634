import { useEffect } from 'react';

export function useClickEsc(handler: () => void, active = true): void {
  useEffect(() => {
    const maybeHandler = (ev: KeyboardEvent): void => {
      const key = ev.key || ev.keyCode;

      if (active && (key === 'Escape' || key === 'Esc' || key === 27)) {
        handler();
      }
    };

    window.addEventListener('keyup', maybeHandler);

    return () => window.removeEventListener('keyup', maybeHandler);
  }, [handler, active]);
}
