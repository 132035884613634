import { isBefore } from 'date-fns';

import { unmask } from './masks';

const availableMonths = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

export function validateCardExpirationDate(
  cardExpirationDate: string
): boolean {
  if (!cardExpirationDate) return false;

  const unmaskedCardExpirationDate = unmask(cardExpirationDate);

  if (unmaskedCardExpirationDate.length !== 4) return false;

  const month = unmaskedCardExpirationDate.slice(0, 2);
  const year = unmaskedCardExpirationDate.slice(2, 4);
  const fullYear = Number(`20${year}`);

  if (!availableMonths.includes(month)) return false;

  const formattedDate = new Date(
    new Date(
      new Date(new Date().setFullYear(fullYear)).setMonth(Number(month) - 1)
    ).setDate(1)
  );

  const formattedCurrentDate = new Date(new Date().setDate(2));

  if (isBefore(formattedDate, formattedCurrentDate)) return false;

  return true;
}
