import { SidebarItemResponseDTO } from './dtos/SidebarItemResponseDTO';

import { setupApi } from '.';

export async function loadSidebarItems(
  ctx = undefined
): Promise<SidebarItemResponseDTO[]> {
  const response = await setupApi(ctx).get<SidebarItemResponseDTO[]>(
    '/sidebar'
  );

  return response.data;
}
