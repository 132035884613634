import { IActionResponse } from './interfaces/IActionResponse';
import { IActionRequest } from './interfaces/ActionRequestDTO';

import { setupApi } from '.';

export async function loadActions(ctx = undefined): Promise<IActionResponse[]> {
  const response = await setupApi(ctx).get('/actions');

  return response.data;
}

export async function createAction(
  action: IActionRequest,
  ctx = undefined
): Promise<IActionResponse> {
  const response = await setupApi(ctx).post('/actions', action);
  return response.data;
}

export async function updateAction(
  action: IActionRequest,
  _id: string,
  ctx = undefined
): Promise<IActionResponse> {
  const response = await setupApi(ctx).put(`/actions/${_id}`, action);
  return response.data;
}
