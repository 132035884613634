import { ISelectOption } from '~/shared/components/Form/Select/types';

import { formatPermissionsGroupsToOptions } from './utils/formatPermissionsGroupsToOptions';

import { ILoadPermissionsGroupsFilters } from './interfaces/ILoadPermissionsGroupsFilters';

import { PermissionsGroupResponseDTO } from './dtos/PermissionsGroupResponseDTO';
import { PermissionsGroupRequestDTO } from './dtos/PermissionsGroupRequestDTO';
import { PaginationResponseDTO } from './dtos/PaginationResponseDTO';

import { setupApi } from '.';

export async function loadPermissionsGroups(
  filters = {} as ILoadPermissionsGroupsFilters,
  ctx = undefined
): Promise<PaginationResponseDTO<PermissionsGroupResponseDTO[]>> {
  const params: Record<string, any> = { limit: 20 };

  if (filters.page) {
    params.page = filters.page;
  }

  const response = await setupApi(ctx).get<
    PaginationResponseDTO<PermissionsGroupResponseDTO[]>
  >('/permissions-groups', { params });

  return response.data;
}

export async function createPermissionsGroup(
  data: PermissionsGroupRequestDTO,
  ctx = undefined
): Promise<PermissionsGroupResponseDTO> {
  const response = await setupApi(ctx).post<PermissionsGroupResponseDTO>(
    '/permissions-groups',
    data
  );

  return response.data;
}

export async function updatePermissionsGroup(
  id: string,
  data: PermissionsGroupRequestDTO,
  ctx = undefined
): Promise<PermissionsGroupResponseDTO> {
  const response = await setupApi(ctx).put<PermissionsGroupResponseDTO>(
    `/permissions-groups/${id}`,
    data
  );

  return response.data;
}

export async function loadPermissionsGroupsOptions(
  ctx = undefined
): Promise<ISelectOption[]> {
  const response = await setupApi(ctx).get<
    PaginationResponseDTO<PermissionsGroupResponseDTO[]>
  >('/permissions-groups', { params: { limit: 9999999999 } });

  const formattedPermissionsGroups = formatPermissionsGroupsToOptions(
    response.data?.data
  );

  return formattedPermissionsGroups;
}
