import { ISelectOption } from '~/shared/components/Form/Select/types';

import { ILoadUsersFilters } from './interfaces/ILoadUsersFilters';

import { UserResponseDTO } from './dtos/UserResponseDTO';
import { UserRequestDTO } from './dtos/UserRequestDTO';
import { UserCompleteRegistrationDTO } from './dtos/UserCompleteRegistrationDTO';
import { SendUserPasswordRecoveryCodeRequestDTO } from './dtos/SendUserPasswordRecoveryCodeRequestDTO';
import { ResetUserPasswordRequestDTO } from './dtos/ResetUserPasswordRequestDTO';
import { PaginationResponseDTO } from './dtos/PaginationResponseDTO';

import { setupApi } from '.';

export async function loadUserById(
  id: string,
  ctx = undefined
): Promise<UserResponseDTO> {
  const response = await setupApi(ctx).get<UserResponseDTO>(`/users/${id}`);

  return response.data;
}

export async function loadSetupUserById(
  id: string,
  ctx = undefined
): Promise<UserResponseDTO> {
  const response = await setupApi(ctx).get<UserResponseDTO>(
    `/users/${id}/setup`
  );

  return response.data;
}

export async function checkUserExistsByDocument(
  document: string,
  ctx = undefined
): Promise<boolean> {
  const response = await setupApi(ctx).get<boolean>(`/users/check-exists`, {
    params: { document },
  });

  return response.data;
}

export async function checkUserExistsByPasswordRecoveryCode(
  passwordRecoveryCode: string,
  ctx = undefined
): Promise<boolean> {
  const response = await setupApi(ctx).get<boolean>(`/users/check-exists`, {
    params: { passwordRecoveryCode },
  });

  return response.data;
}

export async function sendUserPasswordRecoveryCode(
  data: SendUserPasswordRecoveryCodeRequestDTO,
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).post<UserResponseDTO>(
    `/users/send-password-recovery-code`,
    data
  );
}

export async function resetUserPassword(
  data: ResetUserPasswordRequestDTO,
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).put<UserResponseDTO>(`/users/reset-password`, data);
}

export async function completeUserRegistration(
  id: string,
  data: UserCompleteRegistrationDTO,
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).put<UserResponseDTO>(
    `/users/${id}/complete-registration`,
    data
  );
}

export async function loadUsersOptions(
  ctx = undefined
): Promise<ISelectOption[]> {
  const params: Record<string, any> = { limit: 9999999999 };

  const response = await setupApi(ctx).get<ISelectOption[]>('/users/options', {
    params,
  });

  return response.data || [];
}

export async function loadUsers(
  filters = {} as ILoadUsersFilters,
  ctx = undefined
): Promise<PaginationResponseDTO<UserResponseDTO[]>> {
  const response = await setupApi(ctx).get<
    PaginationResponseDTO<UserResponseDTO[]>
  >('/users', { params: filters });

  return response.data;
}

export async function createUser(
  data: UserRequestDTO,
  ctx = undefined
): Promise<UserResponseDTO> {
  const response = await setupApi(ctx).post<UserResponseDTO>('/users', {
    ...data,
    permissionGroup: data.permissionsGroup,
    status: 'active',
  });

  return response.data;
}

export async function deleteUsers(
  ids: string[],
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).delete(`/users`, { params: { ids } });
}

export async function updateUser(
  id: string,
  data: UserRequestDTO,
  ctx = undefined
): Promise<UserResponseDTO> {
  const response = await setupApi(ctx).put<UserResponseDTO>(`/users/${id}`, {
    ...data,
    permissionGroup: data.permissionsGroup,
  });

  return response.data;
}
