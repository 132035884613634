import { formatCustomersFiltersToParams } from './utils/formatCustomersFiltersToParams';

import { ILoadCustomersFilters } from './interfaces/ILoadCustomersFilters';

import { SetCustomerResponsibleUserRequestDTO } from './dtos/SetCustomerResponsibleUserRequestDTO';
import { PaginationResponseDTO } from './dtos/PaginationResponseDTO';
import { CustomerResponseDTO } from './dtos/CustomerResponseDTO';
import { CustomerRequestDTO } from './dtos/CustomerRequestDTO';
import { CustomerLogDTO } from './dtos/CustomerLogDTO';
import { CustomerArchivingHistoryDTO } from './dtos/CustomerArchivingHistoryDTO';
import { AddActionRequestDTO } from './dtos/AddActionRequestDTO';

import { setupApi } from '.';

export async function loadCustomers(
  filters = {} as ILoadCustomersFilters,
  ctx = undefined
): Promise<PaginationResponseDTO<CustomerResponseDTO[]>> {
  const params = formatCustomersFiltersToParams({ ...filters, limit: 20 });

  const response = await setupApi(ctx).get<
    PaginationResponseDTO<CustomerResponseDTO[]>
  >('/customers', { params });

  return response.data;
}

export async function loadCustomerById(
  id: string,
  ctx = undefined
): Promise<CustomerResponseDTO> {
  const [{ data: customer }, { data: archivingHistory }, { data: logs }] =
    await Promise.all([
      await setupApi(ctx).get<CustomerResponseDTO>(`/customers/${id}`),
      await setupApi(ctx).get<CustomerArchivingHistoryDTO[]>(
        '/user-archiving-history',
        { params: { user: id } }
      ),
      await setupApi(ctx).get<CustomerLogDTO[]>(`/customers/${id}/logs`),
    ]);

  return {
    ...customer,
    archivingHistory,
    logs,
  };
}

export async function archiveCustomers(
  ids: string[],
  reason: string,
  ctx = undefined
): Promise<void> {
  await Promise.all(
    ids.map(async (id) => {
      await setupApi(ctx).post<void>(`/customers/${id}/archived`, {
        archiveReason: reason,
      });
    })
  );
}

export async function unarchiveCustomers(
  ids: string[],
  ctx = undefined
): Promise<void> {
  await Promise.all(
    ids.map(async (id) => {
      await setupApi(ctx).post<void>(`/customers/${id}/unArchived`);
    })
  );
}

export async function setCustomerResponsibleUser(
  ids: string[],
  data: SetCustomerResponsibleUserRequestDTO,
  ctx = undefined
): Promise<void> {
  await Promise.all(
    ids.map(async (id) => {
      await setupApi(ctx).patch(
        `/customers/${id}/responsible-user/${data.user}`,
        {
          reason: data.reason,
        }
      );
    })
  );
}

export async function exportCustomers(
  filters = {} as ILoadCustomersFilters,
  ctx = undefined
): Promise<CustomerResponseDTO[]> {
  const params = formatCustomersFiltersToParams(filters);

  const response = await setupApi(ctx).get('/customers/export', { params });

  return response.data;
}

export async function addActionToCustomers(
  ids: string[],
  data: AddActionRequestDTO,
  ctx = undefined
): Promise<any> {
  await Promise.all(
    ids.map(async (id) => {
      await setupApi(ctx).post(`/customers/${id}/actions`, {
        feedback: data.feedback,
        action: data.action._id,
      });
    })
  );
}

export async function updateCustomer(
  data: CustomerRequestDTO,
  id: string,
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).put(`/customers/${id}`, data);
}

export async function createCustomer(
  data: CustomerRequestDTO,
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).post<void>('/customers', data);
}

export async function importCustomers(
  data: CustomerRequestDTO[],
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).post('/customers/import', data);
}
