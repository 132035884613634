import { useCallback } from 'react';

import { useToast as useChakraToast } from '@chakra-ui/react';

export interface IShowToastData {
  title: string;
  description?: string;
  variant?: 'warning' | 'error' | 'success' | 'info';
  duration?: number;
  onCloseComplete?: () => void;
}

interface IUseToastResponse {
  show(data: IShowToastData): void;
}

export const useToast = (): IUseToastResponse => {
  const chakraToast = useChakraToast();

  const show = useCallback(
    (data: IShowToastData) => {
      const tag = `${data.title}${
        data.description ? `-${data.description}` : ''
      }`;

      const isToastActive = chakraToast.isActive(tag);

      if (isToastActive) return;

      chakraToast({
        isClosable: true,
        position: 'top-right',
        id: tag,
        title: data.title,
        status: data.variant,
        description: data.description,
        duration: data.duration || 4000,
        onCloseComplete: data.onCloseComplete,
      });
    },
    [chakraToast]
  );

  return { show };
};
