import styled from 'styled-components';

export const Container = styled.li`
  position: relative;

  > div {
    position: absolute;
    bottom: -15px;
    right: -15px;
    width: 230px;
  }
`;

export const AvatarButton = styled.button`
  transition: all ease-in 0.3s;

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};

  > span {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.onSurface};
  }

  &:hover {
    opacity: 0.75;
  }
`;

export const PopoverContainer = styled.div`
  background: ${({ theme }) => theme.colors.surface};
  position: absolute;
  width: 100%;

  box-shadow: ${({ theme }) => theme.boxShadow.sm};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  border: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};

  margin-top: ${({ theme }) => theme.spacing.xs};
`;

export const PopoverContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.sm};

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.md};

    > strong {
      margin-top: ${({ theme }) => theme.spacing.sm};
      margin-bottom: ${({ theme }) => theme.spacing.xs};
      color: ${({ theme }) => theme.colors.onSurface};
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      word-break: break-word;
    }

    > span {
      color: ${({ theme }) => theme.colors.onSurface};
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      font-weight: 300;
      word-break: break-all;
    }
  }

  a {
    width: 100%;
    &:hover {
      filter: brightness(0.98);
    }
  }

  button,
  a {
    display: flex;
    align-items: center;
    justify-content: center;

    border-top: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
    width: 100%;
    padding: ${({ theme }) => `12px ${theme.spacing.md}`};
    background: ${({ theme }) => theme.colors.surface};
    transition: filter ease-in 0.3s;

    font-size: 14px;
    color: ${({ theme }) => theme.colors.onSurface};

    &:hover {
      filter: brightness(0.98);
    }
  }
`;

export const ClosePopoverButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: ${({ theme }) => theme.spacing.sm};
  right: ${({ theme }) => theme.spacing.sm};

  transition: opacity ease-in 0.3s;

  &:hover {
    opacity: 0.5;
  }

  svg {
    height: 14px;
    width: 14px;
    stroke-width: 3px;
    color: ${({ theme }) => theme.colors.onSurface};
  }
`;
