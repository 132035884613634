import { CSSProperties, MouseEventHandler, ReactNode } from 'react';

import { Tooltip } from '@chakra-ui/react';

import { WithChildren } from '~/shared/types/WithChildren';

import { BadgeType, Container, Wrapper } from './styles';

export interface IBadgeProps {
  type?: BadgeType;
  style?: CSSProperties;
  tooltip?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  children: ReactNode;
  isDisabled?: boolean;
  readonly showTooltip?: boolean;
  readonly onMouseLeave?: () => void;
  href?: string;
}

export const Badge: WithChildren<IBadgeProps> = ({
  children,
  type = 'primary',
  tooltip,
  style,
  onClick,
  isDisabled,
  showTooltip = undefined,
  href,
}) => {
  return (
    <Tooltip
      label={tooltip}
      hasArrow
      closeOnClick={false}
      isOpen={showTooltip}
      closeDelay={0.3}
    >
      <Wrapper
        style={style}
        {...(!!href && { as: 'a', href, target: '_blank' })}
      >
        <Container
          isDisabled={isDisabled}
          type={type}
          href={href}
          onClick={onClick}
        >
          <div>{children}</div>
        </Container>
      </Wrapper>
    </Tooltip>
  );
};
