import styled from 'styled-components';

export const SideBarContainer = styled.aside`
  max-width: 256px;
  min-width: 256px;
  height: 100%;
  background: ${({ theme }) => theme.colors.surface};
  /* box-shadow: ${({ theme }) => theme.boxShadow.sm}; */
  overflow-y: auto;
  z-index: 10;

  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => `${theme.spacing.sm} 0`};

  > header,
  > hr {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 0;

    > header {
      border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
      margin-bottom: ${({ theme }) => theme.spacing.sm};
      padding: ${({ theme }) => `0 ${theme.spacing.md}`};
      min-height: 68px;
      max-height: 68px;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    > hr {
      display: unset;
      border: 0;
      box-shadow: none;

      min-height: 5px;
      max-height: 5px;
      width: 100%;
      background-color: ${({ theme }) => theme.colors.secondary};
    }
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: ${({ theme }) => `0 ${theme.spacing.md}`};
    margin-top: ${({ theme }) => theme.spacing.sm};

    cursor: default;

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.onSurface};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    position: absolute;
    transform: translateX(-300px);
    transition: transform ease-in 0.2s;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
