import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useInsertionEffect,
} from 'react';

import { useRouter } from 'next/router';

import { parseCookies } from 'nookies';
import decode from 'jwt-decode';
import { AxiosError } from 'axios';

import { routes } from '~/shared/constants/routes';
import { cookies } from '~/shared/constants/cookies';

import { useToast } from '~/shared/hooks/useToast';

import { getAnyValidRouteWithPermission } from '~/shared/utils/getAnyValidUserPermission';

import { SignInRequestDTO } from '~/shared/services/api/dtos/SignInRequestDTO';
import {
  signIn as apiSignIn,
  loadUserById,
  setApiDefaults,
} from '~/shared/services/api';

import { WithChildren } from '~/shared/types/WithChildren';
import { IUser } from '../interfaces/IUser';
import { IDecodedToken } from '../interfaces/IDecodedToken';

interface IAuthContextData {
  user: IUser;
  signIn(data: SignInRequestDTO): Promise<IUser>;
  signOut(): void;
  isAuthenticated: boolean;
  hasResale: boolean;
}

const AuthContext = createContext({} as IAuthContextData);

const AuthProvider: WithChildren = ({ children }) => {
  const router = useRouter();
  const toast = useToast();

  const [user, setUser] = useState<IUser | null>(null);

  const isAuthenticated = useMemo(() => !!user, [user]);
  const hasResale = useMemo(() => !!user?.resale?._id, [user]);

  const signOut = useCallback(async () => {
    setApiDefaults(null);

    await router.push(routes.AUTH.SIGN_IN);

    setUser(null);
  }, [router]);

  const signIn = useCallback(
    async (data: SignInRequestDTO) => {
      try {
        const apiResponse = await apiSignIn(data);

        setApiDefaults(apiResponse.token);

        const apiUser = apiResponse.user as any;

        setUser(apiUser);

        const validRoute = getAnyValidRouteWithPermission(apiUser);

        if (!validRoute) {
          toast.show({
            variant: 'warning',
            title: `Ops, você não tem permissão para acessar o sistema!`,
            description: 'Entre em contato com um adiministrador.',
          });

          signOut();
          return;
        }

        router.push(validRoute);

        return apiUser;
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 401) {
          toast.show({
            title: 'Ops, credenciais inválida(s)!',
            description: 'Seu e-mail e/ou senha está(ão) incorreto(s).',
            variant: 'error',
          });
          return;
        }

        toast.show({
          title: 'Ops, não foi possível fazer o login!',
          description: 'Recarregue a página e tente novamente.',
          variant: 'error',
        });
      }
    },
    [router, signOut, toast]
  );

  useInsertionEffect(() => {
    (async () => {
      const { [cookies.AUTH_TOKEN]: token } = parseCookies();

      if (token) {
        try {
          const { _id: userId } = decode<IDecodedToken>(token);

          const userResponse = await loadUserById(userId);

          if (userResponse) {
            setUser(userResponse as any);
            setApiDefaults(token);
          } else {
            signOut();
          }
        } catch {
          signOut();
        }
      }
    })();
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, signIn, signOut, isAuthenticated, hasResale }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): IAuthContextData => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
