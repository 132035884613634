/* eslint-disable func-names */
import * as Yup from 'yup';

import { validatePhoneNumber } from './validatePhoneNumber';
import { validateNaturalDocument } from './validateNaturalDocument';
import { validateLegalDocument } from './validateLegalDocument';
import { validateDocument } from './validateDocument';
import { validateCardType } from './validateCardType';
import { validateCardExpirationDate } from './validateCardExpirationDate';

Yup.addMethod(Yup.string, 'phoneNumber', function (errorMessage) {
  return this.test('test-phone-number-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validatePhoneNumber(value) || createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, 'naturalDocument', function (errorMessage) {
  return this.test('test-document-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validateNaturalDocument(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, 'legalDocument', function (errorMessage) {
  return this.test('test-document-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validateLegalDocument(value) ||
      createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, 'document', function (errorMessage) {
  return this.test('test-document-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validateDocument(value) || createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, 'cardNumber', function (errorMessage) {
  return this.test('test-card-number-valid', errorMessage, function (value) {
    if (!value) return true;

    const { path, createError } = this;

    return (
      validateCardType(value) || createError({ path, message: errorMessage })
    );
  });
});

Yup.addMethod(Yup.string, 'cardExpirationDate', function (errorMessage) {
  return this.test(
    'test-card-expiration-date-valid',
    errorMessage,
    function (value) {
      if (!value) return true;

      const { path, createError } = this;

      return (
        validateCardExpirationDate(value) ||
        createError({ path, message: errorMessage })
      );
    }
  );
});
