export const permissions = {
  DASHBOARD: {
    LIST: '72ef3f6a-2612-11ec-9621-0242ac130002',
  },
  PRE_REGISTERED_CUSTOMERS: {
    LIST: '774941c8-2612-11ec-9621-0242ac130002',
    IMPORT: '7ae037ba-2612-11ec-9621-0242ac130002',
    INFORM_ACTION: '88956df8-2612-11ec-9621-0242ac130002',
    ARCHIVE: '8c23654c-2612-11ec-9621-0242ac130002',
    CREATE: '94447ab8-2612-11ec-9621-0242ac130002',
    DEFINE_RESPONSIBLE_USER: '91b0a34e-2612-11ec-9621-0242ac130002',
    UPDATE: '8e8d167a-2612-11ec-9621-0242ac130002',
    EXPORT: '52cf6f43-8654-4245-9e65-d07582961955',
  },
  ARCHIVED_PRE_REGISTERED_CUSTOMERS: {
    LIST: '9740b9a2-2612-11ec-9621-0242ac130002',
    REACTIVATE: '99cf638a-2612-11ec-9621-0242ac130002',
    UPDATE: '9c0358f0-2612-11ec-9621-0242ac130002',
    EXPORT: '4c11c426-7492-4cf3-8926-340c57feebef',
  },
  REGISTERED_CUSTOMERS: {
    LIST: '9f0355f0-2612-11ec-9621-0242ac130002',
    INFORM_ACTION: 'a2ba3d1c-2612-11ec-9621-0242ac130002',
    GRANT_DAYS: 'a4879996-2612-11ec-9621-0242ac130002',
    UPDATE: 'b387b7ba-2926-42d5-bc40-4050b8d37b28',
    EXPORT: '46e5efae-01cc-4b9f-b022-4e5e29067ccb',
  },
  PARTNERS: {
    LIST: '5d32d6bb-e552-4d67-b275-69c46420eeea',
    INFORM_ACTION: 'e9217854-9541-4992-8cb7-2740576fae4d',
    UPDATE: '53602953-29d2-490d-b537-7fb09508f6f3',
    CREATE: 'b46204d0-2141-4511-b018-6a226384b1ba',
  },
  USERS: {
    LIST: '3db52700-fb23-4507-8d67-bb3433de75b6',
    CREATE: 'afea3952-6e80-41dc-b239-99ac4ae1ca0b',
    UPDATE: '4e4c1eac-f90b-4bd6-a7f6-0a6b04c3249a',
    DELETE: '0694730d-39c1-458b-82f5-9a633a322a86',
  },
  PERMISSIONS_GROUPS: {
    LIST: '10700fce-79a3-48b0-a04e-73d857f484de',
    CREATE: '3f60b09c-56a2-4b07-938b-a20c07dab8cf',
    UPDATE: '41fc3823-92e1-4350-84e5-dd6413d5d7c3',
    DELETE: '',
  },
  ACTIONS: {
    LIST: '4a45be3d-6b09-4eb4-9971-8201a168cbfe',
    CREATE: 'fc60834a-ccb8-4755-ab7f-2c4564970e30',
    UPDATE: '02cede44-d743-434a-b0b5-fe154ccf142e',
  },
  CREATIVES: {
    LIST: '6fe6cf82-6ba5-470e-9fe4-a982b735ee2a',
    CREATE: '9e00b11e-ba4c-444a-b4b7-5f47c4c7fffc',
  },
  OFFERS: {
    LIST: 'ecbb230f-b396-4a5f-b05b-06859467e3d0',
    CREATE: '73e84f06-c3a9-4fb3-817d-482989e73b90',
    UPDATE: 'bea281b8-702f-4d68-a362-41868d3004f6',
  },
  OFFER_PURCHASES: {
    LIST: 'cb10d104-5b84-4fe7-9149-f0130337fa84',
    INFORM_ACTION: 'a7c19717-9510-41e5-b6f7-3fb6aff066c7',
    SEND_CREDENTIALS: '0edce18a-0146-4660-a357-2dc4967a9e7a',
    UPDATE: '9ca9df7c-e23c-4d8c-b882-393a55e84870',
  },
  COURSES: {
    LIST: '76d64368-9825-4f0a-a46f-f8f5d01d64b0',
  },
  MY_COMISSIONS: {
    LIST: '6ff3ee43-fe57-4b5e-ba04-950d7e93f54a',
  },
  COMISSIONS_RANGES: {
    LIST: '64253b2e-b822-42a9-9928-1fbb24160e95',
    CREATE: '3cb97f01-4bf0-4384-b2d7-c37cf0ab5655',
  },
  RESOURCE_GROUPS: {
    LIST: '9985fbf7-4771-43cc-91dd-4fea7628fea3',
    CREATE: 'e9c38104-3b01-4435-acdc-bc644c0a4dd2',
    UPDATE: 'e7a3b37d-21ae-454f-918e-4c40eaf57050',
  },
  PLANS: {
    LIST: 'bfede4e4-de30-47c6-b192-7c4a5723c391',
    CREATE: '162823f0-83c8-4210-9de8-b0132a257ffd',
    UPDATE: 'fde7d51b-02b6-44f3-bd10-2874632ada86',
    CLONE: '30314879-55e9-4f3b-b9bd-732e4889d4a9',
  },
  ADDITIONAL_SERVICES: {
    LIST: 'aa5c34bb-aee8-4546-80d9-f585c3b97176',
    CREATE: '3c899192-faba-4ad0-8422-03ef4af1f906',
    UPDATE: '55cb5492-ae64-4f02-8d95-19f52c41c3a9',
  },
  SUBSCRIPTIONS: {
    BLOCK: '7c36c11b-fee5-48bb-a958-bf074964985b',
    ACTIVATE: '65182460-b83e-4cd0-8a8a-739ef3a2a2ba',
    CANCEL: '06ff666c-faf8-415a-b386-a0c72d04f96a',
  },
};
