import { createContext, useContext, useState, useCallback } from 'react';

import { v4 as uuidV4 } from 'uuid';

import { closeSideBar, SideBar } from '~/shared/components/SideBar';

import { WithChildren } from '~/shared/types/WithChildren';

interface ISideBar {
  content: JSX.Element;
  id: string;
  title?: string;
}

type CloseFn = (id: string) => void;

export interface ISideBarCallbackProps {
  onClose(): void;
  id: string;
  setTitle?(title: string): void;
}

interface IOpenSideBarOptions {
  title: string;
}

type ContentCallback = (props: ISideBarCallbackProps) => JSX.Element;

interface ISideBarContextData {
  open(content: ContentCallback, options?: IOpenSideBarOptions): void;
  close: CloseFn;
}

const SideBarContext = createContext({} as ISideBarContextData);

const SideBarProvider: WithChildren = ({ children }) => {
  const [sideBars, setSideBars] = useState<ISideBar[]>([]);

  const close = useCallback((id: string) => {
    closeSideBar(id);

    setTimeout(() => {
      setSideBars((prevState) => prevState.filter((item) => item.id !== id));
    }, 400);
  }, []);

  const setTitle = useCallback((id: string, title: string) => {
    setSideBars((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return { ...item, title };
        }

        return item;
      })
    );
  }, []);

  const open = useCallback(
    (content: ContentCallback, options?: IOpenSideBarOptions) => {
      setSideBars((prevState) => {
        const id = uuidV4();

        const props: ISideBarCallbackProps = {
          id,
          onClose: () => close(id),
          setTitle: (title: string) => setTitle(id, title),
        };

        return [
          ...prevState,
          { content: content(props), id, title: options?.title },
        ];
      });
    },
    [close, setTitle]
  );

  return (
    <SideBarContext.Provider value={{ open, close }}>
      {sideBars.map(({ content, id, title }) => (
        <SideBar key={id} onClose={() => close(id)} id={id} title={title}>
          {content}
        </SideBar>
      ))}

      {children}
    </SideBarContext.Provider>
  );
};

const useSideBar = (): ISideBarContextData => {
  return useContext(SideBarContext);
};

export { SideBarProvider, useSideBar };
