/* eslint-disable react/require-default-props */
import { ButtonHTMLAttributes, FC } from 'react';

import { IconType } from 'react-icons';

import { Tooltip } from '~/shared/components/Tooltip';

import { Container } from './styles';

interface IToolbarIconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  tooltip?: string;
  icon: IconType;
  badge?: number;
}

export const ToolbarIconButton: FC<IToolbarIconButtonProps> = ({
  icon: Icon,
  tooltip,
  badge,
  ...props
}) => {
  return (
    <Tooltip label={tooltip}>
      <span>
        <Container type="button" {...props}>
          <Icon />

          {badge > 0 && (
            <div>
              <span>{badge}</span>
            </div>
          )}
        </Container>
      </span>
    </Tooltip>
  );
};
