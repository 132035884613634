import { IUser } from '~/modules/auth/interfaces/IUser';

interface IValidateUserPermissionParams {
  user?: Pick<IUser, 'permissionsGroup'> | null;
  permission?: string;
}

export function validateUserPermission({
  user,
  permission,
}: IValidateUserPermissionParams): boolean {
  if (!user) return false;

  if (user.permissionsGroup?.permissionIds) {
    const userPermissions = user.permissionsGroup.permissionIds;
    return userPermissions.includes(permission);
  }

  return false;
}
