import { FC } from 'react';

import { FiX } from 'react-icons/fi';

import { Container } from './styles';

import { Tooltip } from '../../Tooltip';

interface IModalHeaderProps {
  title: string;
  onClose(closeOnX: boolean): void;
}

export const ModalHeader: FC<IModalHeaderProps> = ({ onClose, title }) => {
  return (
    <Container>
      <h2>{title}</h2>

      <Tooltip label="Fechar">
        <button type="button" onClick={() => onClose(true)}>
          <FiX />
        </button>
      </Tooltip>
    </Container>
  );
};
