import { FC } from 'react';

import { FiBell, FiMessageCircle } from 'react-icons/fi';

import Image from 'next/image';

import seruLogoImg from '~/shared/assets/images/seru-logo.png';

import {
  Container,
  ActionsWrapper,
  LeftContent,
  LogoContainer,
} from './styles';

import { ToolbarIconButton } from './ToolbarIconButton';
import { MyAccount } from './MyAccount';
import { SideBarDrawer } from '../SideBarDrawer';

export const Toolbar: FC = () => {
  return (
    <>
      <Container>
        <div>
          <LeftContent>
            <SideBarDrawer />

            <LogoContainer>
              <Image
                height={28}
                width={116}
                alt="SERU"
                src={seruLogoImg.src}
                objectFit="contain"
                objectPosition="left"
              />
            </LogoContainer>
          </LeftContent>

          <ActionsWrapper>
            <ToolbarIconButton
              tooltip="Mensagens - Em breve"
              icon={FiMessageCircle}
            />

            <ToolbarIconButton
              tooltip="Notificações - Em breve"
              icon={FiBell}
            />

            <MyAccount />
          </ActionsWrapper>
        </div>
      </Container>
    </>
  );
};
