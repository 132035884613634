import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  width: 32px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};

  position: relative;

  transition: all 0.3s;

  svg {
    height: 18px;
    width: 18px;
    color: ${({ theme }) => theme.colors.gray[300]};
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary};
    width: 17px;
    height: 17px;
    top: -5px;
    right: -5px;
    border: 1px solid ${({ theme }) => theme.colors.background};

    transition: all 0.3s;

    span {
      text-align: center;
      font-size: 11px;
      line-height: 11px;
      color: ${({ theme }) => theme.colors.onPrimary};
    }
  }

  &:disabled {
    opacity: 0.75;
  }

  &:hover {
    opacity: 0.75;

    > div {
      transform: scale(1.3);
    }
  }
`;
