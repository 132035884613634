import styled from 'styled-components';

export const Container = styled.main`
  display: flex;

  height: calc(100vh - 5px);
  width: 100vw;
`;

export const LeftBox = styled.section`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.secondary};
  padding: ${({ theme }) => theme.spacing.lg};

  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
`;

export const LeftBoxPoweredByContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 5;
  padding: ${({ theme }) => theme.spacing.lg};

  svg {
    width: auto;
    height: 55px;

    path:not(#Vector_10) {
      fill: ${({ theme }) => theme.colors.onPrimary};
    }

    #Vector_10 {
      stroke: ${({ theme }) => theme.colors.onPrimary};
    }
  }

  @media (max-width: 1050px) {
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RightBoxPowredByContainer = styled.div`
  display: none;
  margin-top: ${({ theme }) => theme.spacing.lg};

  svg {
    width: auto;
    height: 45px;

    path:not(#Vector_10) {
      fill: ${({ theme }) => theme.colors.onSurface};
    }

    #Vector_10 {
      stroke: ${({ theme }) => theme.colors.onSurface};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RightBox = styled.main`
  display: grid;
  place-items: center;

  overflow-y: auto;
  overflow-x: hidden;

  background: ${({ theme }) => theme.colors.background};
  width: 100%;
  max-width: 560px;
  padding: 0 ${({ theme }) => theme.spacing.lg};
  height: 100%;

  box-shadow: ${({ theme }) => theme.boxShadow.md};
  z-index: 15;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    box-shadow: none;
    max-width: 100%;
  }
`;

export const RightBoxContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: 368px;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.lg} 0;
  text-align: center;

  > h4 {
    font-size: 16px;
    margin-top: ${({ theme }) =>
      `calc(${theme.spacing.lg} + ${theme.spacing.lg})`};
    font-weight: 400;
  }

  form {
    display: flex;
    flex-direction: column;

    width: 100%;

    padding: ${({ theme }) =>
      `calc(${theme.spacing.lg} + ${theme.spacing.lg}) 0 ${theme.spacing.lg}`};

    #button {
      width: 100%;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    img {
      width: 200px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    align-items: center;
  }
`;
