import { SignInResponseDTO } from './dtos/SignInResponseDTO';
import { SignInRequestDTO } from './dtos/SignInRequestDTO';

import { api } from '.';

export async function signIn(
  data: SignInRequestDTO
): Promise<SignInResponseDTO> {
  const response = await api.post<SignInResponseDTO>('/auth', data);

  return response.data;
}
