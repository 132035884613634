import { routes } from './routes';

import { permissions } from './permissions';

export const permissionsRoutes: Record<string, string> = {
  [permissions.DASHBOARD.LIST]: routes.DASHBOARD,
  [permissions.PARTNERS.LIST]: routes.RESALES.LIST(),
  [permissions.USERS.LIST]: routes.USERS,
  [permissions.PERMISSIONS_GROUPS.LIST]: routes.PERMISSIONS_GROUPS,
  [permissions.ACTIONS.LIST]: routes.ACTIONS,
  [permissions.OFFERS.LIST]: routes.OFFERS.LIST,
  [permissions.OFFER_PURCHASES.LIST]: routes.OFFER_PURCHASES.LIST,
  [permissions.COURSES.LIST]: routes.COURSES,
  [permissions.RESOURCE_GROUPS.LIST]: routes.RESOURCES_GROUPS.LIST,
  [permissions.PLANS.LIST]: routes.PLANS.LIST,
  [permissions.PRE_REGISTERED_CUSTOMERS.LIST]: routes.LEADS.LIST(),
  [permissions.REGISTERED_CUSTOMERS.LIST]: routes.SUBSCRIBERS.LIST(),
};
