/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createContext, useContext, useEffect, useMemo } from 'react';

import { useRouter } from 'next/router';

import { parseCookies, setCookie } from 'nookies';

import { routesLabels } from '~/shared/constants/routesLabels';
import { permissions } from '~/shared/constants/permissions';
import { cookies } from '~/shared/constants/cookies';

import { useToast } from '~/shared/hooks/useToast';
import { useAuth } from '~/modules/auth/hooks/useAuth';

import { withIsMounted } from '~/shared/utils/withIsMounted';
import { validateUserPermission } from '~/shared/utils/validateUserPermission';

import { WithChildren } from '~/shared/types/WithChildren';

interface IPermissionsContextProps {
  readonly ALLOW_DASHBOARD_LIST: boolean;
  readonly ALLOW_LEADS_LIST: boolean;
  readonly ALLOW_LEADS_IMPORT: boolean;
  readonly ALLOW_LEADS_INFORM_ACTION: boolean;
  readonly ALLOW_LEADS_ARCHIVE: boolean;
  readonly ALLOW_LEADS_CREATE: boolean;
  readonly ALLOW_LEADS_DEFINE_RESPONSIBLE_USER: boolean;
  readonly ALLOW_LEADS_UPDATE: boolean;
  readonly ALLOW_LEADS_EXPORT: boolean;
  readonly ALLOW_ARCHIVED_LEADS_LIST: boolean;
  readonly ALLOW_ARCHIVED_LEADS_REACTIVATE: boolean;
  readonly ALLOW_ARCHIVED_LEADS_UPDATE: boolean;
  readonly ALLOW_ARCHIVED_LEADS_EXPORT: boolean;
  readonly ALLOW_SUBSCRIBERS_LIST: boolean;
  readonly ALLOW_SUBSCRIBERS_INFORM_ACTION: boolean;
  readonly ALLOW_SUBSCRIBERS_GRANT_DAYS: boolean;
  readonly ALLOW_SUBSCRIBERS_UPDATE: boolean;
  readonly ALLOW_SUBSCRIBERS_EXPORT: boolean;
  readonly ALLOW_RESALES_LIST: boolean;
  readonly ALLOW_RESALES_INFORM_ACTION: boolean;
  readonly ALLOW_RESALES_UPDATE: boolean;
  readonly ALLOW_RESALES_CREATE: boolean;
  readonly ALLOW_USERS_LIST: boolean;
  readonly ALLOW_USERS_UPDATE: boolean;
  readonly ALLOW_USERS_CREATE: boolean;
  readonly ALLOW_USERS_DELETE: boolean;
  readonly ALLOW_PERMISSIONS_GROUPS_LIST: boolean;
  readonly ALLOW_PERMISSIONS_GROUPS_UPDATE: boolean;
  readonly ALLOW_PERMISSIONS_GROUPS_CREATE: boolean;
  readonly ALLOW_PERMISSIONS_GROUPS_DELETE: boolean;
  readonly ALLOW_ACTIONS_LIST: boolean;
  readonly ALLOW_ACTIONS_UPDATE: boolean;
  readonly ALLOW_ACTIONS_CREATE: boolean;
  readonly ALLOW_CREATIVES_LIST: boolean;
  readonly ALLOW_CREATIVES_CREATE: boolean;
  readonly ALLOW_OFFERS_LIST: boolean;
  readonly ALLOW_OFFERS_UPDATE: boolean;
  readonly ALLOW_OFFERS_CREATE: boolean;
  readonly ALLOW_OFFER_PURCHASES_LIST: boolean;
  readonly ALLOW_OFFER_PURCHASES_UPDATE: boolean;
  readonly ALLOW_OFFER_PURCHASES_INFORM_ACTION: boolean;
  readonly ALLOW_OFFER_PURCHASES_SEND_CREDENTIALS: boolean;
  readonly ALLOW_COURSES_LIST: boolean;
  readonly ALLOW_MY_COMISSIONS_LIST: boolean;
  readonly ALLOW_COMISSIONS_RANGE_LIST: boolean;
  readonly ALLOW_COMISSIONS_RANGE_CREATE: boolean;
  readonly ALLOW_RESOURCES_GROUPS_LIST: boolean;
  readonly ALLOW_RESOURCES_GROUPS_UPDATE: boolean;
  readonly ALLOW_RESOURCES_GROUPS_CREATE: boolean;
  readonly ALLOW_PLANS_LIST: boolean;
  readonly ALLOW_PLANS_UPDATE: boolean;
  readonly ALLOW_PLANS_CREATE: boolean;
  readonly ALLOW_PLANS_CLONE: boolean;
  readonly ALLOW_ADDITIONAL_SERVICES_LIST: boolean;
  readonly ALLOW_ADDITIONAL_SERVICES_CREATE: boolean;
  readonly ALLOW_ADDITIONAL_SERVICES_UPDATE: boolean;
  readonly ALLOW_BLOCK_SUBSCRIPTION: boolean;
  readonly ALLOW_ACTIVATE_SUBSCRIPTION: boolean;
  readonly ALLOW_CANCEL_SUBSCRIPTION: boolean;
}
const PermissionsContext = createContext<IPermissionsContextProps>(
  {} as IPermissionsContextProps
);

const PermissionsProvider: WithChildren = withIsMounted(({ children }) => {
  const { user } = useAuth();
  const router = useRouter();
  const toast = useToast();

  const checkedPermissions: typeof permissions = useMemo(
    () =>
      Object.assign(
        {},
        ...Object.entries(permissions).map(([key, permissionObj]) => ({
          [key]: Object.assign(
            {},
            ...Object.entries(permissionObj).map(
              ([permissionKey, _permission]) => ({
                [permissionKey]: validateUserPermission({
                  user,
                  permission: _permission,
                })
                  ? _permission
                  : undefined,
              })
            )
          ),
        }))
      ),
    [user]
  );

  const parsedCheckedPermissions = useMemo(() => {
    if (!user) {
      const { [cookies.PERMISSIONS]: defaultPermissions } = parseCookies();
      return defaultPermissions
        ? (JSON.parse(defaultPermissions) as IPermissionsContextProps)
        : null;
    }

    const parsed = {
      ALLOW_DASHBOARD_LIST: !!checkedPermissions.DASHBOARD.LIST,
      ALLOW_ACTIONS_CREATE: !!checkedPermissions.ACTIONS.CREATE,
      ALLOW_ACTIONS_LIST: !!checkedPermissions.ACTIONS.LIST,
      ALLOW_ACTIONS_UPDATE: !!checkedPermissions.ACTIONS.UPDATE,
      ALLOW_OFFERS_CREATE: !!checkedPermissions.OFFERS.CREATE,
      ALLOW_OFFERS_LIST: !!checkedPermissions.OFFERS.LIST,
      ALLOW_OFFERS_UPDATE: !!checkedPermissions.OFFERS.UPDATE,
      ALLOW_OFFER_PURCHASES_INFORM_ACTION:
        !!checkedPermissions.OFFER_PURCHASES.INFORM_ACTION,
      ALLOW_OFFER_PURCHASES_LIST: !!checkedPermissions.OFFER_PURCHASES.LIST,
      ALLOW_OFFER_PURCHASES_SEND_CREDENTIALS:
        !!checkedPermissions.OFFER_PURCHASES.SEND_CREDENTIALS,
      ALLOW_OFFER_PURCHASES_UPDATE: !!checkedPermissions.OFFER_PURCHASES.UPDATE,
      ALLOW_COMISSIONS_RANGE_CREATE:
        !!checkedPermissions.COMISSIONS_RANGES.CREATE,
      ALLOW_COMISSIONS_RANGE_LIST: !!checkedPermissions.COMISSIONS_RANGES.LIST,
      ALLOW_COURSES_LIST: !!checkedPermissions.COURSES.LIST,
      ALLOW_CREATIVES_CREATE: !!checkedPermissions.CREATIVES.CREATE,
      ALLOW_CREATIVES_LIST: !!checkedPermissions.CREATIVES.LIST,
      ALLOW_LEADS_ARCHIVE:
        !!checkedPermissions.PRE_REGISTERED_CUSTOMERS.ARCHIVE,
      ALLOW_LEADS_CREATE: !!checkedPermissions.PRE_REGISTERED_CUSTOMERS.CREATE,
      ALLOW_LEADS_DEFINE_RESPONSIBLE_USER:
        !!checkedPermissions.PRE_REGISTERED_CUSTOMERS.DEFINE_RESPONSIBLE_USER,
      ALLOW_LEADS_EXPORT: !!checkedPermissions.PRE_REGISTERED_CUSTOMERS.EXPORT,
      ALLOW_LEADS_IMPORT: !!checkedPermissions.PRE_REGISTERED_CUSTOMERS.IMPORT,
      ALLOW_LEADS_INFORM_ACTION:
        !!checkedPermissions.PRE_REGISTERED_CUSTOMERS.INFORM_ACTION,
      ALLOW_LEADS_LIST: !!checkedPermissions.PRE_REGISTERED_CUSTOMERS.LIST,
      ALLOW_LEADS_UPDATE: !!checkedPermissions.PRE_REGISTERED_CUSTOMERS.UPDATE,
      ALLOW_MY_COMISSIONS_LIST: !!checkedPermissions.MY_COMISSIONS.LIST,
      ALLOW_PERMISSIONS_GROUPS_CREATE:
        !!checkedPermissions.PERMISSIONS_GROUPS.CREATE,
      ALLOW_PERMISSIONS_GROUPS_DELETE:
        !!checkedPermissions.PERMISSIONS_GROUPS.DELETE,
      ALLOW_PERMISSIONS_GROUPS_LIST:
        !!checkedPermissions.PERMISSIONS_GROUPS.LIST,
      ALLOW_PERMISSIONS_GROUPS_UPDATE:
        !!checkedPermissions.PERMISSIONS_GROUPS.UPDATE,
      ALLOW_PLANS_CLONE: !!checkedPermissions.PLANS.CLONE,
      ALLOW_PLANS_CREATE: !!checkedPermissions.PLANS.CREATE,
      ALLOW_PLANS_LIST: !!checkedPermissions.PLANS.LIST,
      ALLOW_PLANS_UPDATE: !!checkedPermissions.PLANS.UPDATE,
      ALLOW_RESALES_CREATE: !!checkedPermissions.PARTNERS.CREATE,
      ALLOW_RESALES_INFORM_ACTION: !!checkedPermissions.PARTNERS.INFORM_ACTION,
      ALLOW_RESALES_LIST: !!checkedPermissions.PARTNERS.LIST,
      ALLOW_RESALES_UPDATE: !!checkedPermissions.PARTNERS.UPDATE,
      ALLOW_RESOURCES_GROUPS_CREATE:
        !!checkedPermissions.RESOURCE_GROUPS.CREATE,
      ALLOW_RESOURCES_GROUPS_LIST: !!checkedPermissions.RESOURCE_GROUPS.LIST,
      ALLOW_RESOURCES_GROUPS_UPDATE:
        !!checkedPermissions.RESOURCE_GROUPS.UPDATE,
      ALLOW_SUBSCRIBERS_GRANT_DAYS:
        !!checkedPermissions.REGISTERED_CUSTOMERS.GRANT_DAYS,
      ALLOW_SUBSCRIBERS_INFORM_ACTION:
        !!checkedPermissions.REGISTERED_CUSTOMERS.INFORM_ACTION,
      ALLOW_SUBSCRIBERS_LIST: !!checkedPermissions.REGISTERED_CUSTOMERS.LIST,
      ALLOW_SUBSCRIBERS_EXPORT:
        !!checkedPermissions.REGISTERED_CUSTOMERS.EXPORT,
      ALLOW_SUBSCRIBERS_UPDATE:
        !!checkedPermissions.REGISTERED_CUSTOMERS.UPDATE,
      ALLOW_USERS_CREATE: !!checkedPermissions.USERS.CREATE,
      ALLOW_USERS_LIST: !!checkedPermissions.USERS.LIST,
      ALLOW_USERS_UPDATE: !!checkedPermissions.USERS.UPDATE,
      ALLOW_USERS_DELETE: !!checkedPermissions.USERS.DELETE,
      ALLOW_ARCHIVED_LEADS_EXPORT:
        !!checkedPermissions.ARCHIVED_PRE_REGISTERED_CUSTOMERS.EXPORT,
      ALLOW_ARCHIVED_LEADS_LIST:
        !!checkedPermissions.ARCHIVED_PRE_REGISTERED_CUSTOMERS.LIST,
      ALLOW_ARCHIVED_LEADS_REACTIVATE:
        !!checkedPermissions.ARCHIVED_PRE_REGISTERED_CUSTOMERS.REACTIVATE,
      ALLOW_ARCHIVED_LEADS_UPDATE:
        !!checkedPermissions.ARCHIVED_PRE_REGISTERED_CUSTOMERS.UPDATE,
      ALLOW_ADDITIONAL_SERVICES_LIST:
        !!checkedPermissions.ADDITIONAL_SERVICES.LIST,
      ALLOW_ADDITIONAL_SERVICES_CREATE:
        !!checkedPermissions.ADDITIONAL_SERVICES.CREATE,
      ALLOW_ADDITIONAL_SERVICES_UPDATE:
        !!checkedPermissions.ADDITIONAL_SERVICES.UPDATE,
      ALLOW_BLOCK_SUBSCRIPTION: !!checkedPermissions.SUBSCRIPTIONS.BLOCK,
      ALLOW_ACTIVATE_SUBSCRIPTION: !!checkedPermissions.SUBSCRIPTIONS.ACTIVATE,
      ALLOW_CANCEL_SUBSCRIPTION: !!checkedPermissions.SUBSCRIPTIONS.CANCEL,
    };

    setCookie(null, cookies.PERMISSIONS, JSON.stringify(parsed), { path: '/' });
    return parsed;
  }, [checkedPermissions, user]);

  useEffect(() => {
    if (router.query?.forbidden) {
      const alias = routesLabels[router.query.forbidden as string];

      toast.show({
        variant: 'warning',
        title: `Você não tem permissão para acessar a tela de ${alias}!`,
        description:
          (router.query.description as string) ||
          'Entre em contato com um adiministrador.',
      });

      router.push({ pathname: router.pathname }, undefined, { shallow: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  return (
    <PermissionsContext.Provider value={{ ...parsedCheckedPermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
});

const usePermissions = () => {
  return useContext(PermissionsContext);
};

export { usePermissions, PermissionsProvider };
