export const PoweredByImg = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1032"
      height="538"
      viewBox="0 0 1032 538"
      fill="none"
    >
      <g id="POWERED BY VETOR (1) 2" clipPath="url(#clip0)">
        <path
          id="Vector"
          d="M116.58 40.22C116.633 43.7107 116.162 47.1897 115.18 50.54C114.319 53.3865 113.027 56.0844 111.35 58.54C109.802 60.7854 107.928 62.7874 105.79 64.48C100.039 68.9681 92.965 71.4291 85.67 71.48H47.85V54.63H85.66C87.6532 54.4861 89.6126 54.0375 91.47 53.3C93.1311 52.6314 94.6485 51.6503 95.94 50.41C97.2055 49.1715 98.1939 47.6786 98.84 46.03C99.5398 44.1746 99.8792 42.2026 99.84 40.22V32.57C99.6505 30.5712 99.1931 28.6069 98.48 26.73C97.8566 25.0484 96.9086 23.5058 95.69 22.19C94.4866 20.9167 93.0282 19.9115 91.41 19.24C89.5766 18.4925 87.6095 18.1283 85.63 18.17H47.98C47.0581 18.0547 46.1222 18.1515 45.2434 18.4529C44.3647 18.7544 43.5664 19.2526 42.9095 19.9095C42.2525 20.5664 41.7544 21.3647 41.4529 22.2434C41.1514 23.1222 41.0547 24.0582 41.17 24.98V94.32H24.45V24.98C24.34 21.178 25.146 17.4052 26.8 13.98C28.2034 11.1465 30.1983 8.6469 32.65 6.64999C34.9109 4.82777 37.4965 3.45017 40.27 2.59C42.7203 1.79689 45.2749 1.37227 47.85 1.33002H85.66C89.0988 1.28318 92.5238 1.77582 95.81 2.79001C98.6387 3.67642 101.316 4.98808 103.75 6.67999C105.982 8.23591 107.958 10.1284 109.61 12.29C111.184 14.3528 112.525 16.5829 113.61 18.94C114.635 21.1786 115.4 23.5272 115.89 25.94C116.349 28.1273 116.587 30.3551 116.6 32.59L116.58 40.22Z"
          fill="black"
        />
        <path
          id="Vector_2"
          d="M227.62 56.7C227.678 62.0522 226.693 67.3643 224.72 72.34C222.897 76.9366 220.177 81.1245 216.72 84.66C213.245 88.1496 209.098 90.8984 204.53 92.74C199.652 94.7007 194.437 95.6826 189.18 95.63H166.54C161.281 95.6757 156.063 94.694 151.18 92.74C146.604 90.9035 142.449 88.1543 138.97 84.66C135.487 81.1216 132.741 76.9273 130.89 72.32C128.898 67.349 127.902 62.035 127.96 56.68V38.93C127.908 33.5852 128.903 28.282 130.89 23.32C132.708 18.7191 135.445 14.5371 138.933 11.0295C142.422 7.52202 146.589 4.76264 151.18 2.92001C156.061 0.944471 161.284 -0.0478529 166.55 -4.36604e-06H189.18C194.433 -0.049959 199.643 0.94254 204.51 2.92001C209.092 4.76253 213.248 7.52275 216.724 11.031C220.199 14.5392 222.92 18.7212 224.72 23.32C226.687 28.2868 227.672 33.5881 227.62 38.93V56.7ZM210.9 38.93C210.942 35.8412 210.406 32.7719 209.32 29.88C208.338 27.2827 206.83 24.9165 204.89 22.93C202.932 20.9778 200.589 19.4554 198.01 18.46C195.191 17.3747 192.191 16.8351 189.17 16.87H166.54C163.496 16.8301 160.472 17.3697 157.63 18.46C155.044 19.4601 152.692 20.9816 150.72 22.93C148.758 24.9065 147.235 27.2747 146.25 29.88C145.168 32.7726 144.635 35.8419 144.68 38.93V56.7C144.635 59.7881 145.168 62.8574 146.25 65.75C147.235 68.3522 148.758 70.7172 150.72 72.69C152.689 74.6449 155.042 76.1704 157.63 77.17C160.472 78.2603 163.496 78.7999 166.54 78.76H189.04C192.081 78.7986 195.101 78.259 197.94 77.17C200.531 76.1703 202.887 74.6449 204.86 72.69C206.816 70.714 208.335 68.3497 209.32 65.75C210.406 62.8581 210.942 59.7887 210.9 56.7V38.93Z"
          fill="black"
        />
        <path
          id="Vector_3"
          d="M361.09 1.32001L345.66 88.51C345.334 90.1955 344.528 91.7511 343.34 92.99C342.168 94.2242 340.64 95.063 338.97 95.39C337.282 95.7022 335.539 95.4965 333.97 94.8C332.427 94.1353 331.132 93.0017 330.27 91.56L299.67 40.83L269 91.56C268.289 92.7917 267.25 93.8024 266 94.48C264.747 95.1539 263.343 95.498 261.92 95.48C259.961 95.5057 258.061 94.8119 256.58 93.53C255.049 92.2466 254.042 90.4462 253.75 88.47L238.18 1.32001H255.18L266.24 62.75L292.62 20.32C293.323 19.0817 294.363 18.0688 295.62 17.4C296.873 16.7248 298.277 16.3806 299.7 16.4C301.096 16.3894 302.473 16.7335 303.7 17.4C304.955 18.1004 306.014 19.1044 306.78 20.32L333.02 62.74L344.02 1.31L361.09 1.32001Z"
          fill="black"
        />
        <path
          id="Vector_4"
          d="M458.68 94.32H398.38C395.804 94.2796 393.247 93.8653 390.79 93.09C388.012 92.2413 385.424 90.8626 383.17 89.03C380.733 87.0187 378.741 84.5225 377.32 81.7C375.66 78.2557 374.854 74.4619 374.97 70.64V9.80002C374.965 8.66452 375.186 7.5394 375.62 6.49003C376.033 5.4766 376.633 4.55032 377.39 3.76002C378.145 2.98277 379.05 2.36699 380.05 1.95002C381.112 1.51227 382.251 1.29128 383.4 1.30002H458.68V18.17H391.68V70.65C391.566 71.5724 391.665 72.5085 391.967 73.3872C392.27 74.2658 392.769 75.0639 393.427 75.7205C394.085 76.3772 394.883 76.8752 395.762 77.1766C396.642 77.478 397.578 77.575 398.5 77.46H458.68V94.32ZM451.18 56.32H398.38V39.32H451.18V56.32Z"
          fill="black"
        />
        <path
          id="Vector_5"
          d="M566.95 32.57C567.001 36.058 566.526 39.5338 565.54 42.88C564.68 45.7269 563.389 48.425 561.71 50.88C560.168 53.1268 558.293 55.1263 556.15 56.81C554.119 58.415 551.907 59.7776 549.56 60.87C547.343 61.9014 545.014 62.6732 542.62 63.17C540.453 63.6287 538.245 63.8666 536.03 63.88L570.94 94.32H545.09L510.25 63.89H498.18V47.04H535.99C537.963 46.8917 539.902 46.4433 541.74 45.71C543.408 45.038 544.935 44.0576 546.24 42.82C547.521 41.5884 548.521 40.0942 549.17 38.44C549.872 36.5644 550.212 34.5724 550.17 32.57V21.93C550.208 21.1891 550.086 20.4488 549.81 19.76C549.622 19.3115 549.31 18.9262 548.91 18.65C548.563 18.426 548.17 18.2825 547.76 18.23C547.35 18.23 546.97 18.17 546.63 18.17H491.54V94.32H474.82V9.80002C474.82 7.56408 475.683 5.41444 477.23 3.80002C477.988 3.02166 478.896 2.40581 479.9 1.99002C480.959 1.55229 482.094 1.33124 483.24 1.34H546.7C549.966 1.22455 553.211 1.91125 556.15 3.34C558.578 4.58869 560.715 6.33696 562.42 8.47C563.992 10.4442 565.17 12.7017 565.89 15.12C566.565 17.2835 566.922 19.5337 566.95 21.8V32.57Z"
          fill="black"
        />
        <path
          id="Vector_6"
          d="M667.06 94.32H606.75C604.174 94.2781 601.617 93.8638 599.16 93.09C596.384 92.2434 593.799 90.8644 591.55 89.03C589.107 87.0219 587.111 84.5252 585.69 81.7C584.033 78.2548 583.23 74.4611 583.35 70.64V9.80002C583.342 8.66517 583.559 7.54003 583.99 6.49003C584.403 5.4766 585.003 4.55032 585.76 3.76002C586.518 2.98165 587.426 2.3658 588.43 1.95002C589.489 1.51229 590.624 1.29127 591.77 1.30002H667.06V18.17H600.06V70.65C599.946 71.5724 600.045 72.5085 600.347 73.3872C600.65 74.2658 601.149 75.0639 601.807 75.7205C602.465 76.3772 603.263 76.8752 604.142 77.1766C605.022 77.478 605.958 77.575 606.88 77.46H667.06V94.32ZM659.54 56.32H606.75V39.32H659.54V56.32Z"
          fill="black"
        />
        <path
          id="Vector_7"
          d="M775.18 55.4C775.238 60.755 774.242 66.0691 772.25 71.04C770.43 75.6348 767.694 79.8106 764.207 83.3128C760.72 86.815 756.557 89.5701 751.97 91.41C747.089 93.3855 741.866 94.3779 736.6 94.33H691.47C690.324 94.3388 689.189 94.1177 688.13 93.68C687.132 93.2678 686.224 92.663 685.46 91.9C684.7 91.124 684.098 90.2068 683.69 89.2C683.256 88.1301 683.038 86.9846 683.05 85.83V9.83001C683.042 8.69516 683.259 7.57001 683.69 6.52001C684.103 5.50658 684.703 4.5803 685.46 3.79C686.218 3.01164 687.126 2.39579 688.13 1.98001C689.189 1.54227 690.324 1.32126 691.47 1.33001H736.6C741.866 1.27794 747.09 2.27039 751.97 4.24999C756.562 6.08968 760.729 8.84813 764.216 12.3561C767.704 15.8641 770.437 20.0476 772.25 24.65C774.236 29.6088 775.231 34.9086 775.18 40.25V55.4ZM758.48 40.22C758.517 37.1488 757.985 34.0972 756.91 31.22C755.933 28.5961 754.41 26.2099 752.44 24.22C750.468 22.2716 748.116 20.75 745.53 19.75C742.688 18.6597 739.664 18.1201 736.62 18.16H699.78V77.45H736.62C739.664 77.4899 742.688 76.9503 745.53 75.86C748.116 74.86 750.468 73.3384 752.44 71.39C754.402 69.4135 755.925 67.0453 756.91 64.44C757.992 61.5474 758.525 58.4781 758.48 55.39V40.22Z"
          fill="black"
        />
        <path
          id="Vector_8"
          d="M926.42 73.89C926.389 76.1568 926.029 78.4071 925.35 80.57C924.633 82.9806 923.455 85.229 921.88 87.19C920.175 89.3127 918.038 91.0478 915.61 92.28C912.682 93.724 909.443 94.4247 906.18 94.32H842.73C841.581 94.3288 840.442 94.1078 839.38 93.67C838.377 93.262 837.467 92.6545 836.705 91.8845C835.944 91.1144 835.347 90.1977 834.95 89.19C834.512 88.1208 834.291 86.9753 834.3 85.82V9.82001C834.312 7.58646 835.174 5.44117 836.71 3.82001C837.468 3.04165 838.376 2.4258 839.38 2.01002C840.442 1.57227 841.581 1.35128 842.73 1.36002H898.66C900.909 1.39219 903.141 1.76302 905.28 2.46003C907.678 3.21944 909.913 4.42001 911.87 6.00001C913.991 7.72382 915.723 9.87788 916.95 12.32C918.374 15.2931 919.06 18.5655 918.95 21.86V25.04C918.909 28.3034 918.411 31.545 917.47 34.67C916.451 38.1738 914.841 41.4779 912.71 44.44C914.637 45.6328 916.419 47.0456 918.02 48.65C919.71 50.3553 921.169 52.2751 922.36 54.36C923.673 56.6631 924.682 59.127 925.36 61.69C926.117 64.6293 926.487 67.6549 926.46 70.69L926.42 73.89ZM909.7 70.65C909.744 68.6247 909.404 66.6094 908.7 64.71C908.053 63.026 907.07 61.4914 905.81 60.2C904.531 58.9316 902.997 57.9498 901.31 57.32C899.414 56.6279 897.408 56.289 895.39 56.32H857.72V39.32H887.87C889.888 39.3511 891.894 39.0121 893.79 38.32C895.48 37.6903 897.015 36.7047 898.29 35.43C899.551 34.1456 900.526 32.6085 901.15 30.92C901.839 29.02 902.178 27.0109 902.15 24.99V21.81C902.15 19.39 901 18.18 898.68 18.18H851.04V77.47H906.18C906.48 77.47 906.85 77.47 907.28 77.41C907.687 77.3654 908.08 77.2323 908.43 77.02C908.829 76.7421 909.141 76.3574 909.33 75.91C909.621 75.2355 909.754 74.5037 909.72 73.77L909.7 70.65Z"
          fill="black"
        />
        <path
          id="Vector_9"
          d="M1027.55 32.44C1027.6 35.931 1027.11 39.4092 1026.1 42.75C1025.22 45.5883 1023.94 48.2833 1022.28 50.75C1020.75 53.0037 1018.87 55.0044 1016.72 56.68C1014.68 58.2743 1012.47 59.6361 1010.13 60.74C1007.92 61.7742 1005.6 62.5461 1003.21 63.04C1001.04 63.4992 998.835 63.7371 996.62 63.75H989.87V94.32H973.03V63.77H966.34C964.125 63.7566 961.917 63.5187 959.75 63.06C957.355 62.5648 955.027 61.7929 952.81 60.76C950.462 59.6699 948.25 58.3072 946.22 56.7C944.077 55.0163 942.202 53.0168 940.66 50.77C938.974 48.2973 937.672 45.5831 936.8 42.72C935.791 39.3882 935.302 35.9208 935.35 32.44V1.32001H952.07V32.46C952.027 34.482 952.367 36.4939 953.07 38.39C953.71 40.0777 954.694 41.6136 955.96 42.9C957.24 44.1839 958.786 45.1705 960.49 45.79C962.412 46.4863 964.446 46.8252 966.49 46.79H996.64C998.23 46.53 999.86 46.19 1001.53 45.79C1003.17 45.3936 1004.72 44.6765 1006.09 43.68C1007.55 42.5923 1008.72 41.1684 1009.5 39.53C1010.49 37.3097 1010.95 34.8894 1010.85 32.46V1.32001H1027.57L1027.55 32.44Z"
          fill="black"
        />
        <path
          id="Vector_10"
          d="M194.62 522.68C293.821 522.68 374.24 442.261 374.24 343.06C374.24 243.859 293.821 163.44 194.62 163.44C95.4186 163.44 15 243.859 15 343.06C15 442.261 95.4186 522.68 194.62 522.68Z"
          stroke="black"
          strokeWidth="30"
          strokeMiterlimit="10"
        />
        <path
          id="Union"
          d="M113.08 343.06C113.08 306.777 136.778 276.029 169.54 265.45V292.373C150.897 301.615 138.08 320.841 138.08 343.06C138.08 374.286 163.394 399.6 194.62 399.6C225.846 399.6 251.16 374.286 251.16 343.06C251.16 320.845 238.348 301.622 219.71 292.378V265.453C252.467 276.035 276.16 306.781 276.16 343.06C276.16 388.093 239.653 424.6 194.62 424.6C149.587 424.6 113.08 388.093 113.08 343.06Z"
          fill="black"
        />
        <path
          id="Vector_11"
          d="M506.89 500.06C506.915 501.913 506.635 503.758 506.06 505.52C505.547 507.049 504.802 508.489 503.85 509.79C502.962 511.007 501.881 512.071 500.65 512.94C498.317 514.603 495.653 515.746 492.84 516.29C491.58 516.549 490.297 516.683 489.01 516.69H446.12V506.44H489.01C489.917 506.498 490.826 506.375 491.684 506.078C492.543 505.781 493.333 505.316 494.01 504.71C494.611 504.1 495.078 503.371 495.383 502.571C495.687 501.77 495.823 500.915 495.78 500.06C495.788 499.171 495.629 498.289 495.31 497.46C495.003 496.682 494.534 495.978 493.934 495.395C493.334 494.812 492.617 494.364 491.83 494.08C490.93 493.751 489.978 493.588 489.02 493.6H463.44C461.469 493.576 459.51 493.28 457.62 492.72C455.532 492.128 453.57 491.158 451.83 489.86C448.855 487.414 446.795 484.032 445.987 480.266C445.179 476.5 445.67 472.571 447.38 469.12C448.482 467.104 449.998 465.346 451.83 463.96C453.569 462.659 455.531 461.686 457.62 461.09C459.51 460.527 461.468 460.227 463.44 460.2H501.29V470.44H463.44C462.54 470.386 461.638 470.515 460.789 470.82C459.94 471.124 459.161 471.597 458.5 472.21C457.903 472.831 457.439 473.567 457.134 474.374C456.83 475.18 456.693 476.039 456.73 476.9C456.685 477.759 456.819 478.617 457.124 479.421C457.429 480.225 457.897 480.957 458.5 481.57C459.171 482.166 459.952 482.624 460.8 482.917C461.647 483.211 462.545 483.334 463.44 483.28H489.1C490.381 483.318 491.656 483.472 492.91 483.74C494.286 484.021 495.627 484.45 496.91 485.02C498.252 485.6 499.524 486.331 500.7 487.2C503.229 489.053 505.114 491.651 506.09 494.63C506.65 496.384 506.921 498.219 506.89 500.06V500.06Z"
          fill="black"
        />
        <path
          id="Vector_12"
          d="M580.49 493.84C580.526 497.103 579.871 500.337 578.57 503.33C577.32 506.168 575.499 508.717 573.22 510.82C570.864 512.964 568.113 514.629 565.12 515.72C561.854 516.916 558.398 517.513 554.92 517.48H539.86C536.375 517.512 532.913 516.916 529.64 515.72C526.64 514.631 523.881 512.967 521.52 510.82C519.175 508.659 517.311 506.029 516.049 503.1C514.788 500.171 514.158 497.009 514.2 493.82V483.05C514.184 479.784 514.866 476.552 516.2 473.57C517.456 470.733 519.284 468.187 521.57 466.09C523.937 463.95 526.694 462.286 529.69 461.19C532.96 459.978 536.423 459.375 539.91 459.41H554.97C558.45 459.374 561.908 459.977 565.17 461.19C568.159 462.289 570.909 463.953 573.27 466.09C575.553 468.185 577.375 470.732 578.62 473.57C579.917 476.56 580.572 479.79 580.54 483.05L580.49 493.84ZM569.37 483.05C569.397 481.164 569.039 479.293 568.32 477.55C567.652 475.95 566.646 474.514 565.37 473.34C564.045 472.135 562.491 471.209 560.8 470.62C558.913 469.949 556.923 469.611 554.92 469.62H539.86C537.844 469.608 535.841 469.947 533.94 470.62C532.242 471.214 530.679 472.138 529.34 473.34C528.046 474.509 527.022 475.945 526.34 477.55C525.62 479.293 525.263 481.164 525.29 483.05V493.84C525.264 495.726 525.622 497.597 526.34 499.34C527.022 500.945 528.046 502.381 529.34 503.55C530.681 504.748 532.244 505.672 533.94 506.27C535.841 506.943 537.844 507.282 539.86 507.27H554.86C556.876 507.283 558.88 506.944 560.78 506.27C562.478 505.677 564.042 504.752 565.38 503.55C566.68 502.386 567.704 500.948 568.38 499.34C569.098 497.597 569.456 495.726 569.43 493.84L569.37 483.05Z"
          fill="black"
        />
        <path
          id="Vector_13"
          d="M647.81 516.69H597.09C596.328 516.698 595.572 516.563 594.86 516.29C594.205 516.049 593.604 515.682 593.09 515.21C592.582 514.741 592.176 514.172 591.899 513.539C591.622 512.905 591.479 512.221 591.48 511.53V460.2H602.6V506.44H647.81V516.69Z"
          fill="black"
        />
        <path
          id="Vector_14"
          d="M712.81 511.53C712.811 512.22 712.665 512.902 712.38 513.53C712.106 514.158 711.696 514.718 711.18 515.17C710.655 515.638 710.048 516.004 709.39 516.25C708.695 516.52 707.955 516.656 707.21 516.65H682.18C679.766 516.644 677.358 516.406 674.99 515.94C672.583 515.47 670.236 514.735 667.99 513.75C665.712 512.761 663.563 511.498 661.59 509.99C657.423 506.815 654.359 502.411 652.83 497.4C651.937 494.485 651.502 491.449 651.54 488.4V460.2H662.66V488.44C662.59 490.947 663.036 493.442 663.97 495.77C665.45 499.346 668.2 502.248 671.69 503.92C673.146 504.636 674.671 505.206 676.24 505.62C677.489 505.956 678.763 506.187 680.05 506.31C681.14 506.4 681.86 506.44 682.21 506.44H701.75V460.2H712.87L712.81 511.53Z"
          fill="black"
        />
        <path
          id="Vector_15"
          d="M781.18 470.44H756.68V516.69H745.56V470.44H720.98V460.2H781.18V470.44Z"
          fill="black"
        />
        <path
          id="Vector_16"
          d="M800.52 516.69H789.4V460.2H800.52V516.69Z"
          fill="black"
        />
        <path
          id="Vector_17"
          d="M877.89 493.84C877.927 497.104 877.27 500.34 875.96 503.33C874.717 506.169 872.899 508.72 870.62 510.82C868.262 512.967 865.507 514.631 862.51 515.72C859.244 516.917 855.788 517.513 852.31 517.48H837.26C833.775 517.512 830.313 516.916 827.04 515.72C824.036 514.634 821.274 512.969 818.91 510.82C816.628 508.718 814.803 506.168 813.55 503.33C812.229 500.342 811.564 497.106 811.6 493.84V483.05C811.568 479.787 812.233 476.555 813.55 473.57C814.798 470.732 816.624 468.185 818.91 466.09C821.279 463.948 824.039 462.284 827.04 461.19C830.309 459.977 833.773 459.374 837.26 459.41H852.31C855.79 459.373 859.248 459.976 862.51 461.19C865.503 462.286 868.257 463.95 870.62 466.09C872.903 468.183 874.722 470.731 875.96 473.57C877.266 476.558 877.923 479.789 877.89 483.05V493.84ZM866.77 483.05C866.812 481.168 866.472 479.297 865.77 477.55C865.085 475.947 864.062 474.511 862.77 473.34C861.442 472.134 859.884 471.209 858.19 470.62C856.303 469.948 854.313 469.61 852.31 469.62H837.26C835.244 469.607 833.24 469.946 831.34 470.62C829.64 471.209 828.076 472.134 826.74 473.34C825.446 474.509 824.422 475.945 823.74 477.55C823.038 479.297 822.697 481.168 822.74 483.05V493.84C822.699 495.722 823.039 497.593 823.74 499.34C824.422 500.945 825.446 502.381 826.74 503.55C828.078 504.752 829.642 505.677 831.34 506.27C833.24 506.944 835.244 507.283 837.26 507.27H852.26C854.276 507.282 856.279 506.943 858.18 506.27C859.877 505.675 861.44 504.75 862.78 503.55C864.074 502.381 865.098 500.945 865.78 499.34C866.481 497.593 866.821 495.722 866.78 493.84L866.77 483.05Z"
          fill="black"
        />
        <path
          id="Vector_18"
          d="M950.18 512.32C950.18 513.012 950.026 513.695 949.73 514.32C949.437 514.951 949.021 515.517 948.506 515.984C947.99 516.451 947.387 516.81 946.73 517.04C946.034 517.306 945.295 517.442 944.55 517.44C943.845 517.437 943.145 517.315 942.48 517.08C941.784 516.842 941.148 516.452 940.62 515.94L899.99 476.86V516.65H888.88V464.57C888.884 463.535 889.237 462.531 889.88 461.72C890.51 460.871 891.361 460.21 892.34 459.81C893.375 459.412 894.5 459.308 895.59 459.51C896.669 459.693 897.668 460.195 898.46 460.95L939.08 499.95V460.2H950.18V512.32Z"
          fill="black"
        />
        <path
          id="Vector_19"
          d="M1022.01 500.06C1022.03 501.913 1021.75 503.758 1021.18 505.52C1020.67 507.049 1019.92 508.489 1018.97 509.79C1018.08 511.007 1017 512.071 1015.77 512.94C1014.58 513.787 1013.3 514.501 1011.96 515.07C1010.67 515.614 1009.33 516.023 1007.96 516.29C1006.7 516.549 1005.42 516.683 1004.13 516.69H961.24V506.44H1004.18C1005.09 506.498 1006 506.375 1006.85 506.078C1007.71 505.781 1008.5 505.316 1009.18 504.71C1009.78 504.1 1010.25 503.371 1010.55 502.571C1010.86 501.77 1010.99 500.915 1010.95 500.06C1010.96 499.171 1010.8 498.289 1010.48 497.46C1010.17 496.68 1009.7 495.975 1009.1 495.392C1008.5 494.809 1007.78 494.362 1006.99 494.08C1006.09 493.75 1005.14 493.587 1004.19 493.6H978.62C976.649 493.576 974.69 493.28 972.8 492.72C970.712 492.128 968.749 491.158 967.01 489.86C964.035 487.414 961.975 484.032 961.167 480.266C960.359 476.5 960.85 472.571 962.56 469.12C963.661 467.104 965.178 465.346 967.01 463.96C968.749 462.659 970.711 461.686 972.8 461.09C974.69 460.527 976.648 460.227 978.62 460.2H1016.47V470.44H978.62C977.72 470.386 976.818 470.515 975.969 470.82C975.12 471.124 974.341 471.597 973.68 472.21C973.083 472.831 972.618 473.567 972.314 474.374C972.01 475.18 971.872 476.039 971.91 476.9C971.865 477.759 971.999 478.617 972.304 479.421C972.609 480.225 973.077 480.957 973.68 481.57C974.35 482.166 975.132 482.624 975.98 482.917C976.827 483.211 977.724 483.334 978.62 483.28H1004.18C1005.46 483.319 1006.74 483.473 1007.99 483.74C1009.37 484.021 1010.71 484.45 1011.99 485.02C1013.33 485.6 1014.6 486.331 1015.78 487.2C1018.31 489.053 1020.19 491.651 1021.17 494.63C1021.74 496.382 1022.03 498.216 1022.01 500.06V500.06Z"
          fill="black"
        />
        <path
          id="Vector_20"
          d="M705.57 249.7C705.709 259.265 704.339 268.792 701.51 277.93C699.02 285.773 695.307 293.174 690.51 299.86C686.046 306.049 680.672 311.528 674.57 316.11C668.714 320.513 662.376 324.237 655.68 327.21C649.303 330.048 642.628 332.161 635.78 333.51C629.562 334.783 623.236 335.453 616.89 335.51L716.95 418.79H642.87L543 335.51H508.58V289.32H616.93C622.576 288.925 628.133 287.699 633.42 285.68C638.172 283.864 642.546 281.182 646.32 277.77C649.959 274.43 652.822 270.334 654.71 265.77C656.771 260.667 657.791 255.203 657.71 249.7V220.57C657.829 218.52 657.487 216.47 656.71 214.57C656.144 213.35 655.251 212.312 654.13 211.57C653.127 210.935 651.991 210.541 650.81 210.42C649.74 210.305 648.666 210.245 647.59 210.24H489.41V418.75H441.5V187.32C441.482 184.209 442.108 181.127 443.34 178.27C444.535 175.481 446.256 172.948 448.41 170.81C450.595 168.643 453.198 166.942 456.06 165.81C459.108 164.603 462.362 163.998 465.64 164.03H647.52C658.187 164.03 667.217 165.893 674.61 169.62C681.524 172.975 687.656 177.745 692.61 183.62C697.071 188.991 700.449 195.175 702.56 201.83C704.473 207.742 705.484 213.907 705.56 220.12L705.57 249.7Z"
          fill="black"
        />
        <path
          id="Vector_21"
          d="M1031.8 344.32C1031.92 352.67 1030.64 360.982 1028.02 368.91C1025.7 375.81 1022.33 382.31 1018.02 388.18C1013.97 393.641 1009.07 398.415 1003.51 402.32C988.057 413.143 969.685 419.026 950.82 419.19H756.51V373.04H950.82C960.507 373.04 968.03 370.433 973.39 365.22C978.75 360.007 981.417 353.023 981.39 344.27C981.437 340.263 980.714 336.284 979.26 332.55C977.879 329.031 975.758 325.85 973.041 323.223C970.323 320.595 967.073 318.582 963.51 317.32C959.442 315.856 955.143 315.134 950.82 315.19H834.97C826.045 315.07 817.179 313.724 808.62 311.19C799.165 308.518 790.272 304.158 782.37 298.32C774.049 292.115 767.178 284.175 762.23 275.05C756.897 265.463 754.23 253.743 754.23 239.89C754.23 226.037 756.897 214.347 762.23 204.82C767.21 195.718 774.076 187.784 782.37 181.55C790.253 175.651 799.15 171.246 808.62 168.55C817.179 166.016 826.045 164.67 834.97 164.55H1006.42V210.73H834.97C825.41 210.73 817.953 213.397 812.6 218.73C807.247 224.063 804.58 231.107 804.6 239.86C804.6 248.74 807.267 255.74 812.6 260.86C817.933 265.98 825.39 268.557 834.97 268.59H951.18C956.975 268.741 962.745 269.41 968.42 270.59C974.64 271.881 980.71 273.814 986.53 276.36C992.595 279 998.347 282.307 1003.68 286.22C1009.19 290.267 1014.08 295.106 1018.18 300.58C1022.52 306.399 1025.89 312.874 1028.18 319.76C1030.72 327.693 1031.95 335.99 1031.8 344.32V344.32Z"
          fill="black"
        />
        <path
          id="Vector_22"
          d="M811.35 372.85H642.97V419.2H811.35V372.85Z"
          fill="black"
        />
        <path
          id="Vector_23"
          d="M209.54 229.38H179.54V331.7H209.54V229.38Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="1031.8" height="537.69" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
