import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
  }

  html, body, #__next {
    min-height: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.onBackground};
  }

  body {
    text-rendering: optimizelegibility !important;
    -webkit-font-smoothing: antialiased !important;
    overflow-y: hidden;
  }

  main {
    background-color: ${({ theme }) => theme.colors.background};
  }

  fieldset {
    border: none;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;
  }

  input {
    border: none;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  .pcr-app {
    border: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
    border-radius: ${({ theme }) => theme.borderRadius.sm} !important;
    box-shadow: none !important;
  }

  .pcr-selection {
    grid-gap: ${({ theme }) => theme.spacing.sm} !important;
    padding: ${({ theme }) => theme.spacing.sm} !important;
    padding-bottom: 0 !important;

    display: flex !important;
    flex-direction: column  !important;
  }

  .pcr-color-preview {
    margin-left: 0 !important;
    width: max-content !important;
  }

  .pcr-color-chooser {
    margin-right: 0 !important;
    margin-left: 0 !important;
    width: 100%  !important;
    min-height: 8px !important; 
  }

  .pcr-swatches {
    margin: 0 !important;
    padding: 0 !important;
  }
  
  .pcr-interaction {
    padding: ${({ theme }) => theme.spacing.sm} !important;
    gap: ${({ theme }) => theme.spacing.sm} !important;
    margin: 0 !important;
  }

  .pcr-result {
    width: 100%;
    flex: unset !important;
    border-radius: ${({ theme }) => theme.borderRadius.sm} !important;
    background: ${({ theme }) => theme.colors.surface} !important;
    border: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
    height: 36px !important;
    padding: 0 12px !important; 
    box-shadow: none !important;
    filter: brightness(1) !important;

    color: ${({ theme }) => theme.colors.onSurface} !important;

    transition: border-color ease-in 0.2s !important;
    
    margin: 0 !important;

    &:focus-within {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }

  .pcr-save {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 36px;
    border-radius: ${({ theme }) => theme.borderRadius.sm} !important;
    background: ${({ theme }) => theme.colors.primary} !important;
    border: ${({ theme }) => `1px solid ${theme.colors.primary}`} !important;
    color: ${({ theme }) => theme.colors.onPrimary};
    padding: 0 12px !important;

    font-size: 12px !important;
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    line-height: 36px !important;

    transition: filter 0.3s ease-in !important;
    box-shadow: none !important;

    margin: 0 !important;

    &:hover:not(:disabled) {
      filter: brightness(0.88);
    }
  }
`;
