import styled from 'styled-components';

import { TModalSize } from '~/shared/hooks/useModal';

interface IOverlay {
  readonly size: TModalSize;
}

const getModalSize: Record<TModalSize, string> = {
  full: '96%',
  lg: '700px',
  md: '660px',
  sm: '480px',
};

export const Overlay = styled.div<IOverlay>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.backdrop};

  z-index: 999;

  transition: opacity 0.25s;
  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.md};

  > div {
    max-width: ${({ size }) => getModalSize[size]};
    width: 100%;

    max-height: 100%;

    background-color: ${({ theme }) => theme.colors.surface};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    box-shadow: ${({ theme }) => theme.boxShadow.sm};

    transform: translateY(36px);
    transition: all ease-out 0.3s;
    opacity: 0;
  }
`;

export const Content = styled.div`
  max-height: 100%;
  display: flex;
  flex-direction: column;
`;
