import { ILoadCustomersFilters } from '../interfaces/ILoadCustomersFilters';

export function formatCustomersFiltersToParams(
  filters: ILoadCustomersFilters
): Record<string, string> {
  const params: Record<string, string> = {};

  if (filters.limit) {
    params.limit = String(filters.limit);
  }

  if (filters.status) {
    params.status = filters.status;
  }

  if (filters.createdAt) {
    params.createdAt = filters.createdAt;
  }

  if (filters.resale) {
    params.resale = filters.resale;
  }

  if (filters.search) {
    params.filter = filters.search;
  }

  if (filters.page) {
    params.page = String(filters.page);
  }

  if (filters.pendings) {
    params.noActions = String(filters.pendings);
  }

  if (filters.type) {
    params.type = filters.type;
  }

  if (typeof filters.archived !== 'undefined') {
    params.archived = String(filters.archived);

    if (filters.status) {
      delete params.status;
    }
  }

  return params;
}
