/* eslint-disable consistent-return */
import { FC, useEffect, useState } from 'react';

export function withIsMounted<P = any>(FunctionalComponent: FC<P>) {
  return (props: P): JSX.Element | null => {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
      setIsMounted(true);

      return () => {
        setIsMounted(false);
      };
    }, []);

    if (!isMounted) return null;

    return <FunctionalComponent {...props} />;
  };
}
