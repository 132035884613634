import { routes } from './routes';

export const routesLabels = {
  [routes.PERMISSIONS_GROUPS]: 'Grupos de permissões',
  [routes.DASHBOARD]: 'Início',
  [routes.LEADS.LIST()]: 'Leads',
  [routes.SUBSCRIBERS.LIST()]: 'Assinantes',
  [routes.RESALES.LIST()]: 'Revendas',
  [routes.ACTIONS]: 'Ações',
  [routes.OFFER_PURCHASES.LIST]: 'Compras de oferta',
  [routes.COURSES]: 'Cursos e treinamentos',
  [routes.USERS]: 'Usuários',
  [routes.PLANS.LIST]: 'Cadastro de planos',
  [routes.RESOURCES_GROUPS.LIST]: 'Cadastro de recursos',
  [routes.HELP.LIST]: 'Área de ajuda',
};
