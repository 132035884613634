import { formatCompaniesFiltersToParams } from '~/shared/services/api/utils/formatCompaniesFiltersToParams';

import { ISubscriberAdditionalService } from '~/modules/subscribers/interfaces/ISubscriberAdditionalService';
import { ISubscriberAdditionalIntegration } from '~/modules/subscribers/interfaces/ISubscriberAdditionalIntegration';
import { ILoadCompanyConsumptionByIdFilters } from './interfaces/ILoadCompanyConsumptionByIdFilters';
import { ILoadCompaniesFilters } from './interfaces/ILoadCompaniesFilters';

import { PaginationResponseDTO } from './dtos/PaginationResponseDTO';
import { CompanySubscriptionDTO } from './dtos/CompanySubscriptionDTO';
import { CompanyResponseDTO } from './dtos/CompanyResponseDTO';
import { CompanyConsumptionDTO } from './dtos/CompanyConsumptionDTO';
import { AddActionRequestDTO } from './dtos/AddActionRequestDTO';

import { api, setupApi } from '.';

export async function loadCompanies(
  filters = {} as ILoadCompaniesFilters,
  ctx = undefined
): Promise<PaginationResponseDTO<CompanyResponseDTO[]>> {
  const params = formatCompaniesFiltersToParams({ ...filters, limit: 20 });

  const response = await setupApi(ctx).get<
    PaginationResponseDTO<CompanyResponseDTO[]>
  >('/companies', { params });

  return response.data;
}

export async function loadCompanyById(
  id: string,
  ctx = undefined
): Promise<CompanyResponseDTO> {
  const response = await setupApi(ctx).get<CompanyResponseDTO>(
    `/companies/${id}`
  );

  return response.data;
}

export async function loadCompanyLasOrderDateById(
  id: string
): Promise<Date | undefined> {
  const response = await setupApi().get(`/companies/${id}/last-order`);

  return !!response.data?.createdAt && new Date(response.data.createdAt);
}

export async function loadCompanyBilling(id: string): Promise<number> {
  const response = await setupApi().get(`/companies/${id}/billing`);

  return response.data || 0;
}

export async function loadCompanySubscriptionById(
  id: string,
  ctx = undefined
): Promise<CompanySubscriptionDTO> {
  const response = await setupApi(ctx).get<CompanySubscriptionDTO>(
    `/companies/${id}/subscription`
  );

  return response.data;
}

export async function loadCompanyResaleCodeById(
  id: string,
  ctx = undefined
): Promise<string> {
  const response = await setupApi(ctx).get<string>(`/companies/${id}/resale`);

  return response.data;
}

export async function loadCompanyConsumptionById(
  id: string,
  filters = {} as ILoadCompanyConsumptionByIdFilters,
  ctx = undefined
): Promise<CompanyConsumptionDTO> {
  const params: Record<string, any> = {};

  if (filters.initialDate && filters.finalDate) {
    params.initialDate = filters.initialDate;
    params.finalDate = filters.finalDate;
  }

  const response = await setupApi(ctx).get<CompanyConsumptionDTO>(
    `/companies/${id}/consumption`,
    { params }
  );

  return response.data;
}

export async function exportCompanies(
  filters = {} as ILoadCompaniesFilters,
  ctx = undefined
): Promise<CompanyResponseDTO[]> {
  const params = formatCompaniesFiltersToParams(filters);

  const response = await setupApi(ctx).get('/companies/export', { params });

  return response.data;
}

export async function addActionToCompanies(
  ids: string[],
  data: AddActionRequestDTO,
  ctx = undefined
): Promise<any> {
  await Promise.all(
    ids.map(async (id) => {
      await setupApi(ctx).post(`/companies/${id}/actions`, {
        feedback: data.feedback,
        action: data.action._id,
      });
    })
  );
}

export async function saveAdditionalServices(
  id: string,
  additionalServices: ISubscriberAdditionalService[],
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).put(
    `/companies/${id}/additional-services`,
    additionalServices
  );
}

export async function addCompanyAdditionalIntegration(
  id: string,
  additionalIntegrationId: string
): Promise<ISubscriberAdditionalIntegration> {
  const response = await api.post<ISubscriberAdditionalIntegration>(
    `/companies/${id}/additional-apps`,
    {
      appId: additionalIntegrationId,
    }
  );

  return response.data;
}

export async function removeCompanyAdditionalIntegration(
  id: string,
  additionalIntegrationId: string
): Promise<void> {
  await api.delete<ISubscriberAdditionalIntegration>(
    `/companies/${id}/additional-apps/${additionalIntegrationId}`
  );
}
