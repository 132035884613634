import { HomeStepResponseDTO } from './dtos/HomeStepResponseDTO';

import { setupApi } from '.';

export async function loadHomeSteps(
  ctx = undefined
): Promise<HomeStepResponseDTO[]> {
  const response = await setupApi(ctx).get<HomeStepResponseDTO[]>(
    '/home-steps'
  );

  return response.data;
}
