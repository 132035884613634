import { PermissionResponseDTO } from './dtos/PermissionResponseDTO';

import { setupApi } from '.';

export async function loadPermissions(
  ctx = undefined
): Promise<PermissionResponseDTO[]> {
  const response = await setupApi(ctx).get<PermissionResponseDTO[]>(
    '/permissions'
  );

  return (
    (response.data || []).map((permission) => {
      const newPermission = { ...permission };

      if (newPermission._id?.includes('ignore')) {
        delete newPermission._id;
      }

      return newPermission;
    }) || []
  );
}
