import creditCardType, { types as CardType } from 'credit-card-type';

import { unmask } from './masks';

const validCardTypes: string[] = [
  CardType.MASTERCARD,
  CardType.MAESTRO,
  CardType.VISA,
  CardType.DISCOVER,
  CardType.JCB,
  CardType.HIPERCARD,
  CardType.DINERS_CLUB,
  CardType.AMERICAN_EXPRESS,
  CardType.ELO,
];

export function validateCardType(cardNumber: string): boolean {
  if (!cardNumber) return false;

  const unmaskedCardNumber = unmask(cardNumber);

  const cardTypes = creditCardType(unmaskedCardNumber);

  const cardType = cardTypes?.[0];

  if (!cardType || !cardType.lengths.includes(unmaskedCardNumber.length))
    return false;

  const isCardTypeValid = validCardTypes.includes(cardType.type);

  return isCardTypeValid;
}
