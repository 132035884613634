import styled from 'styled-components';

export const Container = styled.hr`
  border: 0;
  box-shadow: none;

  height: 5px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
`;
