import { ISelectOption } from '~/shared/components/Form/Select/types';

import { ILoadPlansOptionsFilters } from './interfaces/ILoadPlansOptionsFilters';
import { ILoadPlansFilters } from './interfaces/ILoadPlansFilters';

import { PlanResponseDTO } from './dtos/PlanResponseDTO';
import { PlanRequestDTO } from './dtos/PlanRequestDTO';
import { PaginationResponseDTO } from './dtos/PaginationResponseDTO';

import { setupApi } from '.';

export async function loadPlansOptions(
  filters = {} as ILoadPlansOptionsFilters,
  ctx = undefined
): Promise<ISelectOption[]> {
  const params: Record<string, any> = {};

  if (filters.resale) {
    params.resale = filters.resale;
  }

  const response = await setupApi(ctx).get<ISelectOption[]>('/plans/options', {
    params,
  });

  return response.data || [];
}

export async function loadPlans(
  filters = {} as ILoadPlansFilters,
  ctx = undefined
): Promise<PaginationResponseDTO<PlanResponseDTO[]>> {
  const params: Record<string, any> = {};

  if (filters.page) {
    params.page = filters.page;
  }

  const response = await setupApi(ctx).get<
    PaginationResponseDTO<PlanResponseDTO[]>
  >('/plans');

  return response.data;
}

export async function loadPlanById(
  id: string,
  ctx = undefined
): Promise<PlanResponseDTO> {
  const response = await setupApi(ctx).get<PlanResponseDTO>(`/plans/${id}`);

  return response.data;
}

export async function createPlan(
  data: PlanRequestDTO,
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).post('/plans', data);
}

export async function updatePlan(
  id: string,
  data: PlanRequestDTO,
  ctx = undefined
): Promise<void> {
  await setupApi(ctx).put(`/plans/${id}`, data);
}
