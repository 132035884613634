import { FC, Fragment, useMemo } from 'react';

import {
  FiAward,
  FiBriefcase,
  FiFileText,
  FiGift,
  FiGrid,
  FiHelpCircle,
  FiHome,
  FiLayers,
  FiList,
  FiPlusCircle,
  FiShield,
  FiShoppingCart,
  FiTag,
  FiUser,
  FiUserCheck,
  FiUsers,
} from 'react-icons/fi';

import { routes } from '~/shared/constants/routes';

import { usePermissions } from '~/shared/hooks/usePermissions';

import { Container } from './styles';

import { NavMenu } from './NavMenu';
import { NavItem, INavItemProps } from './NavItem';

export const SideBarNav: FC = () => {
  const {
    ALLOW_DASHBOARD_LIST,
    ALLOW_LEADS_LIST,
    ALLOW_SUBSCRIBERS_LIST,
    ALLOW_RESALES_LIST,
    ALLOW_USERS_LIST,
    ALLOW_COURSES_LIST,
    ALLOW_OFFER_PURCHASES_LIST,
    ALLOW_PLANS_LIST,
    ALLOW_RESOURCES_GROUPS_LIST,
    ALLOW_PERMISSIONS_GROUPS_LIST,
    ALLOW_ACTIONS_LIST,
    ALLOW_OFFERS_LIST,
    ALLOW_ADDITIONAL_SERVICES_LIST,
  } = usePermissions();

  const navItems: Pick<
    INavItemProps,
    | 'href'
    | 'label'
    | 'icon'
    | 'shouldMatchExactHref'
    | 'comingSoon'
    | 'menu'
    | 'permissions'
  >[] = useMemo(
    () => [
      {
        href: routes.DASHBOARD,
        icon: FiHome,
        label: 'Estatísticas',
        permissions: [ALLOW_DASHBOARD_LIST],
      },
      {
        href: routes.LEADS.LIST(),
        icon: FiUsers,
        label: 'Leads',
        permissions: [ALLOW_LEADS_LIST],
      },
      {
        href: routes.SUBSCRIBERS.LIST(),
        icon: FiUserCheck,
        label: 'Assinantes',
        permissions: [ALLOW_SUBSCRIBERS_LIST],
      },
      {
        href: routes.RESALES.LIST(),
        icon: FiBriefcase,
        label: 'Revendas',
        permissions: [ALLOW_RESALES_LIST],
      },
      {
        href: routes.ACTIONS,
        icon: FiFileText,
        label: 'Ações',
        permissions: [ALLOW_ACTIONS_LIST],
      },
      {
        href: '',
        icon: FiTag,
        label: 'Campanha',
        menu: [
          {
            label: 'Ofertas',
            icon: FiLayers,
            href: routes.OFFERS.LIST,
            permissions: [ALLOW_OFFERS_LIST],
          },
          {
            label: 'Compras de oferta',
            icon: FiShoppingCart,
            href: routes.OFFER_PURCHASES.LIST,
            permissions: [ALLOW_OFFER_PURCHASES_LIST],
          },
        ],
      },
      {
        href: routes.COURSES,
        icon: FiAward,
        label: 'Cursos e treinamentos',
        permissions: [ALLOW_COURSES_LIST],
      },
      {
        href: routes.PERMISSIONS_GROUPS,
        icon: FiShield,
        label: 'Grupos de permissões',
        permissions: [ALLOW_PERMISSIONS_GROUPS_LIST],
      },
      {
        href: routes.USERS,
        icon: FiUser,
        label: 'Usuários',
        permissions: [ALLOW_USERS_LIST],
      },
      {
        href: '',
        icon: FiGift,
        label: 'Planos',
        menu: [
          {
            label: 'Lista de planos',
            icon: FiList,
            href: routes.PLANS.LIST,
            permissions: [ALLOW_PLANS_LIST],
          },
          {
            label: 'Grupos de recursos',
            icon: FiGrid,
            href: routes.RESOURCES_GROUPS.LIST,
            permissions: [ALLOW_RESOURCES_GROUPS_LIST],
          },
        ],
      },
      {
        href: routes.HELP.LIST,
        icon: FiHelpCircle,
        label: 'Área de ajuda',
        permissions: [true],
      },
      {
        href: routes.ADDITIONAL_SERVICES.LIST,
        icon: FiPlusCircle,
        label: 'Serviços adicionais',
        permissions: [ALLOW_ADDITIONAL_SERVICES_LIST],
      },
    ],
    [
      ALLOW_DASHBOARD_LIST,
      ALLOW_LEADS_LIST,
      ALLOW_SUBSCRIBERS_LIST,
      ALLOW_RESALES_LIST,
      ALLOW_ACTIONS_LIST,
      ALLOW_OFFERS_LIST,
      ALLOW_OFFER_PURCHASES_LIST,
      ALLOW_COURSES_LIST,
      ALLOW_PERMISSIONS_GROUPS_LIST,
      ALLOW_USERS_LIST,
      ALLOW_PLANS_LIST,
      ALLOW_RESOURCES_GROUPS_LIST,
      ALLOW_ADDITIONAL_SERVICES_LIST,
    ]
  );

  return (
    <Container>
      <ul>
        {navItems.map((item) => (
          <Fragment key={`${String(item.href)}-${item.label}`}>
            {item.menu?.length > 0 ? (
              <>
                {item.menu.some((itemMenu) =>
                  itemMenu.permissions?.some((p) => !!p)
                ) && <NavMenu {...item} />}
              </>
            ) : (
              <>
                {!item.permissions ||
                  (item.permissions.some((p) => !!p) && <NavItem {...item} />)}
              </>
            )}
          </Fragment>
        ))}
      </ul>
    </Container>
  );
};
